<div
  class="message-input-container px-3 py-2 flex align-items-center justify-content-between border-top-1 bg-white-100"
>
  @if (isEmailReplied) {
    <p class="text-color-tertiary-500 font-medium text-sm cursor-pointer ml-3" (click)="onCancelEmail()">Cancel</p>
  } @else {
    <div></div>
  }
  <div class="flex gap-2 align-items-center">
    <label for="fileInput" class="cursor-pointer">
      <fa-icon
        pTooltip="Attach File"
        tooltipPosition="bottom"
        tooltipStyleClass="text-xs"
        icon="paperclip"
        class="mr-2 icon-section__icon text-color-tertiary-500 flex justify-content-center align-items-center"
      ></fa-icon>
    </label>
    <input
      type="file"
      id="fileInput"
      (change)="onFileSelected($event)"
      [attr.accept]="acceptedTypes"
      style="display: none"
    />
    @if (draftLoading) {
      <p-progressSpinner
        styleClass="w-1rem h-1rem"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        class="mr-2 icon-section__icon text-color-tertiary-500 flex justify-content-center align-items-center cursor-pointer"
      />
    } @else {
      <fa-icon
        pTooltip="Save as Draft"
        tooltipPosition="bottom"
        tooltipStyleClass="text-xs"
        icon="floppy-disk"
        (click)="onSaveDraft()"
        class="mr-2 icon-section__icon text-color-tertiary-500 flex justify-content-center align-items-center cursor-pointer"
      ></fa-icon>
    }
    <div class="button-section ml-2">
      <p-splitButton
        [label]="loading ? 'Sending...' : 'Send'"
        (onClick)="onSendEmail()"
        [model]="menuItems"
        class="p-button-primary"
        size="small"
        [disabled]="loading"
      ></p-splitButton>
    </div>
  </div>
</div>
