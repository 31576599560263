import { Component, EventEmitter, inject, Input, Output, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, FormGroup } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { IconsModule } from '@app/shared/modules/icons.module';
import { TooltipModule } from 'primeng/tooltip';
import { SplitButtonModule } from 'primeng/splitbutton';
import { FileUploadService } from '@app/services/file-upload.service';
import { FileUploader } from 'ng2-file-upload';
import { IFile } from '@app/shared/models/omail';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-email-footer',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    IconsModule,
    TooltipModule,
    SplitButtonModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './create-email-footer.component.html',
  styleUrl: './create-email-footer.component.scss',
})
export class CreateEmailFooterComponent {
  message: string = '';
  @Output() fileUploaded = new EventEmitter<IFile>();
  @Output() submitEmail = new EventEmitter<void>();
  @Output() saveDraft = new EventEmitter<void>();
  @Output() discardEmail = new EventEmitter<void>();
  @Input() emailForm!: FormGroup;
  @Input() loading = false;
  @Input() draftLoading = false;
  @Input() isEmailReplied = false;

  menuItems = [
    {
      label: 'Schedule Send',
      icon: 'pi pi-calendar',
      command: () => this.scheduleMessage(),
    },
  ];

  uploader: FileUploader;
  acceptedTypes: string;
  fileUploadService = inject(FileUploadService);
  constructor() {
    this.acceptedTypes = this.fileUploadService.allowedTypes.join(',');
    // Configure the uploader - adjust URL as per your backend
    this.uploader = new FileUploader({
      url: '/api/upload',
      allowedMimeType: this.fileUploadService.allowedTypes,
      maxFileSize: this.fileUploadService.maxFileSizeMB * 1024 * 1024,
      autoUpload: false, // we can choose to manually upload or handle after selection
    });

    // Listen to upload success
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      // Assuming your backend returns a JSON with { fileUrl, fileName, fileType }
      const res = JSON.parse(response);
      this.fileUploaded.emit({
        file: res.file,
        fileUrl: res.fileUrl,
        fileName: res.fileName,
        fileType: res.fileType,
        fileSize: (res.size / 1024).toFixed(2),
      });
    };
  }

  onSendEmail(): void {
    this.submitEmail.emit();
  }
  sendMessage() {
    if (this.message.trim()) {
      this.message = '';
    }
  }

  scheduleMessage() {}

  onSaveDraft() {
    this.saveDraft.emit();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      // Validate file using FileUploadService
      if (!this.fileUploadService.validateFile(file)) {
        return;
      }

      // Add to uploader queue
      this.uploader.clearQueue();
      this.uploader.addToQueue([file]);

      // Option A: Actually upload to server and emit the returned file data
      // this.uploader.uploadAll();

      // Option B: If you just want to display it locally without actual upload:
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result as string;
        const blob = this.base64ToBlob(base64Data, file.type);
        const fileUrl = URL.createObjectURL(blob);

        this.fileUploaded.emit({
          file: file,
          fileUrl: fileUrl,
          fileName: file.name,
          fileType: file.type,
          fileSize: (file.size / 1024).toFixed(2),
        });
      };
      reader.readAsDataURL(file);
    }
  }

  base64ToBlob(base64Data: string, mimeType: string): Blob {
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteArrays = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays[i] = byteCharacters.charCodeAt(i);
    }
    return new Blob([byteArrays], { type: mimeType });
  }

  onCancelEmail() {
    this.discardEmail.emit();
  }
}
