<div class="email-details border-left-1 pl-2 relative">
  @for (email of emails; track $index) {
    <div class="email-details__email p-2 border rounded-md bg-white text-sm border-bottom-1">
      <div class="mb-2">
        <span class="font-bold mr-1">From:</span>
        <span>{{ email.from.name }} &lt;{{ email.from.email }}&gt;</span>
      </div>

      <div class="mb-2">
        <span class="font-bold mr-1">Sent:</span>
        <span>{{ email.sentDate }}</span>
      </div>

      <div class="mb-2">
        <span class="font-bold mr-1">To:</span>
        @for (recipient of email.to; track $index) {
          <span>{{ recipient.name }} &lt;{{ recipient.email }}&gt;</span>
        }
      </div>

      <div class="mb-4">
        <span class="font-bold mr-1">Subject:</span>
        <span>{{ email.subject }}</span>
      </div>

      <div class="text-gray-700">
        @for (line of email.body; track $index) {
          <p>{{ line }}</p>
        }
      </div>
    </div>
  }
</div>
