<form [formGroup]="emailForm" class="create-email flex flex-column flex-1">
  <app-create-email-header
    [action]="action"
    [emailForm]="emailForm"
    (close)="onSaveDraftClick(true).subscribe()"
    [draft]="draftData"
    [isEmailReplied]="isEmailReplied()"
  ></app-create-email-header>
  <div class="flex flex-column flex-1 justify-content-between create-email overflow-auto overflow-x-hidden">
    <div
      class="create-email__replied flex flex-column flex-1 justify-content-between overflow-auto overflow-x-hidden"
      [ngClass]="{ 'm-3 border-1 border-round-lg': isEmailReplied() }"
    >
      @if (isEmailReplied()) {
        <app-email-fields [draft]="draftData" [action]="action" [emailForm]="emailForm" [(isEditing)]="isEditing" />
      }
      <app-email-editor
        [emailDetails]="emailDetails"
        [action]="action"
        [uploadedFile]="uploadedFile"
        [emailForm]="emailForm"
        (sendVideoUrl)="handleVideoUrl($event)"
      ></app-email-editor>
      <app-create-email-footer
        (fileUploaded)="handleFileUpload($event)"
        [emailForm]="emailForm"
        (submitEmail)="onSubmitEmail()"
        [loading]="loading()"
        [draftLoading]="draftLoading()"
        (saveDraft)="onSaveDraftClick(false).subscribe()"
        (discardEmail)="onSaveDraftClick(true).subscribe()"
        [isEmailReplied]="isEmailReplied()"
      ></app-create-email-footer>
    </div>
    @if (isEmailReplied()) {
      <div class="pb-3 max-h-15rem">
        <app-email-overview [canBeClicked]="true" [emailDetails]="emailDetails" />
        <div class="h-1rem w-1rem"></div>
      </div>
    }
  </div>
  @if (readLoading()) {
    <div class="loader-wrapper h-screen absolute w-full h-full flex justify-content-center align-items-center">
      <o-loader bgColor="null" />
    </div>
  }
  @if (closePageLoading()) {
    <div class="loader-wrapper loader">
      <o-loader bgColor="null" />
    </div>
  }
</form>
