<div class="email-field flex align-items-center justify-content-between flex-1">
  <div class="flex align-items-center gap-1 flex-1" (click)="toggleEdit(true)">
    <label [for]="fieldId()" class="email-field__label text-color-tertiary-500 font-normal">
      {{ label() }}:{{ ' ' }}
    </label>
    @if (!isEditing()) {
      <ng-container>
        <span class="email-text text-color-tertiary-500 cursor-pointer">
          {{ emails().join(', ') || 'No emails added' }}
        </span>
      </ng-container>
    } @else {
      <app-email-chip-input
        [emails]="emails()"
        [placeholder]="placeholder()"
        (emailsChange)="onEmailsChange($event)"
      ></app-email-chip-input>
    }
  </div>

  @if (removable()) {
    <ng-container>
      <span class="email-field__remove text-sm cursor-pointer text-color-danger-500" (click)="removeField()">
        Remove {{ label() }}
      </span>
    </ng-container>
  }
</div>
