import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IApiResponseData,
  ICalendarDataPayload,
  ICalendarEvent,
  ICalendarEventPayload,
  ICalendarEventsData,
  IEventsApiResponse,
} from '@app/shared/models';
import { environment } from '@src/environments/environment';
import { IMyProfileData } from '@app/shared/models/settings.model';

@Injectable({
  providedIn: 'root',
})
export class CalendarApi {
  oesUserApiUrl = environment.oesUserApiUrl;
  oMailApiUrl = environment.oMailApiUrl;

  private httpClient = inject(HttpClient);

  private readonly options = {
    withCredentials: true,
  };
  private timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  getProfileInfo(): Observable<IApiResponseData<IMyProfileData>> {
    return this.httpClient.get<IApiResponseData<IMyProfileData>>(
      this.oesUserApiUrl + 'api/v2/users/profile',
      this.options
    );
  }

  getCalendarEventsData(data: ICalendarDataPayload): Observable<IEventsApiResponse<ICalendarEventsData[]>> {
    return this.httpClient.post<IEventsApiResponse<ICalendarEventsData[]>>(
      this.oesUserApiUrl + 'calendar',
      data,
      this.options
    );
  }

  deleteEvent(id: string, startDate: string, endDate: string) {
    return this.httpClient.post<IEventsApiResponse<ICalendarEventsData[]>>(
      this.oMailApiUrl + 'api/v2/deleteEvent',
      { eventId: id, startDateTime: startDate, endDateTime: endDate },
      this.options
    );
  }

  getEventById(id: string, startDate: string, endDate: string): Observable<ICalendarEvent> {
    return this.httpClient.post<ICalendarEvent>(
      this.oMailApiUrl + 'api/v2/getEventById',
      { eventId: id, startDateTime: startDate, endDateTime: endDate },
      this.options
    );
  }

  setAttendance(attendStatus: string, eventId: number): Observable<ICalendarEvent> {
    const options = {
      headers: new HttpHeaders({
        timezone: this.timeZone,
      }),
      withCredentials: true,
    };

    const params = new HttpParams().set('eventId', eventId.toString());

    return this.httpClient.patch<ICalendarEvent>(
      this.oMailApiUrl + 'attendance',
      { attendStatus: attendStatus },
      { ...options, params }
    );
  }

  CreateEvent(fd: FormData): Observable<IApiResponseData<any>> {
    const CreatEventOptions = {
      headers: new HttpHeaders({
        timezone: this.timeZone,
      }),
      withCredentials: true,
    };
    return this.httpClient.post<IApiResponseData<any>>(this.oMailApiUrl + 'saveOrUpdateEvent', fd, CreatEventOptions);
  }
}
