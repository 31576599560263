import { Component, DestroyRef, effect, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { IconsModule } from '@app/shared/modules/icons.module';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { EventClickArg } from '@fullcalendar/core';
import { ChipsModule } from 'primeng/chips';
import { FloatLabelModule } from 'primeng/floatlabel';
import { StyleClassModule } from 'primeng/styleclass';
import { EditorModule } from 'primeng/editor';
import { CalendarFacade } from '@app/pages/calendar/services/calendar.facade';
import { assetUrl } from '@src/single-spa/asset-url';
import { OButtonDirective, OToastV2Service } from 'o-suite-lib';
import { DomSanitizer } from '@angular/platform-browser';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ICalendarEvent, IGuest } from '@app/shared/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EditEventModalComponent } from '@app/pages/calendar/edit-event-modal/edit-event-modal.component';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-view-event-popup',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    ConfirmDialogModule,
    FaIconComponent,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    DividerModule,
    IconsModule,
    ConfirmPopupModule,
    InputNumberModule,
    RadioButtonModule,
    ChipsModule,
    FloatLabelModule,
    StyleClassModule,
    EditorModule,
    OButtonDirective,
    ProgressSpinnerModule,
    EditEventModalComponent,
    NgOptimizedImage,
    TooltipModule,
  ],
  providers: [ConfirmationService, DatePipe],
  templateUrl: './view-event-popup.component.html',
  styleUrl: './view-event-popup.component.scss',
})
export class ViewEventPopupComponent implements OnInit {
  private confirmationService = inject(ConfirmationService);
  private calendarFacade = inject(CalendarFacade);
  private sanitizer = inject(DomSanitizer);
  private destroyRef = inject(DestroyRef);
  private datePipe = inject(DatePipe);
  toaster = inject(OToastV2Service);

  @Input('selectedEventInfo') set setSelectedDayInfo(value: {
    selectedEventInfo: EventClickArg;
    isOpenPopup: boolean;
  }) {
    if (value) {
      if (value.isOpenPopup) this.closeViewEvent();
      this.selectInfo = value.selectedEventInfo;
      this.getEventById();
    }
  }

  @Output('eventDeleted') eventDeleted: EventEmitter<boolean> = new EventEmitter();

  selectInfo!: EventClickArg;
  sanitizedHtml: any;
  event!: ICalendarEvent;
  organizer!: IGuest;
  profileEmailId: string = '';
  currentAttendStatus!: string;
  isLoadingChangeAttendanceStatus: string = '';
  attendingStatusMap: { [key: string]: { src: string; alt: string } } = {
    ATTENDING: { src: 'icons/approved-badge.svg', alt: 'approved' },
    REJECTING: { src: 'icons/not-approved-badge.svg', alt: 'not-approved' },
    PENDING: { src: 'icons/pending-approve.svg', alt: 'pending' },
  };
  isShowEditEvent: boolean = false;
  isCancelEventLoading = this.calendarFacade.isCancelEventLoading;

  constructor() {
    effect(
      () => {
        const isCreateEventLoading = this.isCancelEventLoading();
        if (!isCreateEventLoading.value && !isCreateEventLoading.isError) {
          this.eventDeleted.emit();
          this.closeViewEvent();
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit() {
    this.profileEmailId = sessionStorage.getItem('profileEmailId') || '';
  }

  getEventById() {
    this.calendarFacade
      .getEventById(
        this.selectInfo?.event?._def?.publicId,
        this.selectInfo?.event?._def?.extendedProps['start'],
        this.selectInfo?.event?._def?.extendedProps['end']
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: ICalendarEvent) => {
        this.event = res;

        this.formatEventData();
        this.getAttendingStatusForCurrentUser();
        this.openViewEventPopup();
      });
  }

  formatEventData() {
    const index = this.event.invitees.findIndex((invitee: any) => invitee.isOrganiser);
    if (index !== -1) this.organizer = this.event.invitees.splice(index, 1)[0];
    this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(this.event?.description);
  }

  openViewEventPopup() {
    this.confirmationService.confirm({
      target: this.selectInfo?.jsEvent?.target as EventTarget,
      dismissableMask: false,
      key: 'custom',
    });
  }

  closeViewEvent() {
    this.isShowEditEvent = false;
    this.confirmationService.close();
  }

  deleteEvent() {
    const eventDate = this.datePipe.transform(this.event.eventDate, 'yyyy-MM-ddTHH:mm:ss') || '';
    const fromTime = this.datePipe.transform(this.event.startTime, 'yyyy-MM-dd HH:mm:ss') || '';
    const endTime = this.datePipe.transform(this.event.endTime, 'yyyy-MM-dd HH:mm:ss') || '';

    const event = {
      ...this.event,
      eventDate: eventDate,
      startTime: fromTime,
      endTime: endTime,
      repeatEvent: !this.event.repeatEvent ? 0 : 1,
    };

    let fd: FormData = new FormData();
    fd.append('data', JSON.stringify(event));
    fd.append('action', '2');

    this.calendarFacade.CreateEvent(fd);
  }

  openOmeetLink(link: string) {
    window.open(link, '_blank');
  }

  getAttendingStatusForCurrentUser() {
    const currentUserInvitationInfo =
      this.organizer.inviteeEmailId === this.profileEmailId
        ? this.organizer
        : this.event.invitees.find((invitee: IGuest) => invitee.inviteeEmailId === this.profileEmailId);

    this.currentAttendStatus = currentUserInvitationInfo?.attendStatus || 'PENDING';
  }

  sendAttendStatus(attendStatus: string) {
    if (this.currentAttendStatus !== attendStatus) {
      this.isLoadingChangeAttendanceStatus = attendStatus;
      this.calendarFacade
        .setAttendance(attendStatus, this.event.eventId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          (res) => {
            this.currentAttendStatus = attendStatus;
            this.getEventById();
            this.isLoadingChangeAttendanceStatus = '';
          },
          (error) => {
            this.isLoadingChangeAttendanceStatus = '';
          }
        );
    }
  }

  editEvent() {
    this.isShowEditEvent = true;
  }

  isBeforeNow(date: string): boolean {
    const today = new Date();
    const inputDate = new Date(date);
    return inputDate < today;
  }

  protected readonly assetUrl = assetUrl;
}
