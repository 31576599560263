import { Component, input } from '@angular/core';
import { IReadEmailResponse } from '@app/shared/models/omail';
import { formatDate } from '@app/utils/format-date';

@Component({
  selector: 'app-email-detils-overlay-panel',
  standalone: true,
  imports: [],
  templateUrl: './email-detils-overlay-panel.component.html',
})
export class EmailDetilsOverlayPanelComponent {
  emailDetails = input<IReadEmailResponse>();

  formatDate(date: string | number): string {
    return formatDate(date, true);
  }
}
