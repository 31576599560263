import { Email, IReadEmailResponse } from '@app/shared/models/omail';
import { Component, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { EMAIL_ACTIONS, MAILBOX_ROUTES } from '@app/constants';
import { EmailService } from '@app/services/Email.service';
import { IconsModule } from '@app/shared/modules/icons.module';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-replys',
  standalone: true,
  imports: [IconsModule, TooltipModule, CommonModule],
  templateUrl: './replys.component.html',
  styleUrl: './replys.component.scss',
})
export class ReplysComponent {
  router = inject(Router);
  emailService = inject(EmailService);

  emailDetails = input<IReadEmailResponse>();
  isTextIncluded = input(false);
  emailActions = EMAIL_ACTIONS;
  showReplyAll: boolean = true; // Determines whether to show Reply All

  ngOnInit(): void {
    this.checkReplyAllVisibility();
  }

  private checkReplyAllVisibility(): void {
    const toRecipients = this.emailDetails()?.to ?? [];
    const ccRecipients = this.emailDetails()?.cc ?? [];
    const bccRecipients = this.emailDetails()?.bcc ?? [];

    const totalVisibleRecipients = toRecipients.length + ccRecipients.length;

    if (totalVisibleRecipients <= 1) {
      this.showReplyAll = false;
      return;
    }

    if (toRecipients.length === 0 && ccRecipients.length === 0 && bccRecipients.length > 0) {
      this.showReplyAll = false;
      return;
    }

    this.showReplyAll = true;
  }
  navigateToCreateEmail(event: Event, action: string): void {
    event.stopPropagation();

    const emailDetails = this.emailDetails();

    if (emailDetails) {
      this.emailService.setCurrentEmail(emailDetails);
      this.router.navigate([`${MAILBOX_ROUTES.MAIL}/${MAILBOX_ROUTES.NEW_EMAIL}/${emailDetails?.sno}`], {
        queryParams: { action },
      });
    }
  }
}
