<div>
  @if (!userImage) {
    <div
      (click)="triggerFileUpload(fileInput)"
      class="cursor-pointer flex flex-column justify-content-center align-items-center w-13rem h-12rem upload-container custom-border-radius"
    >
      <p-avatar shape="circle" icon="pi pi-user" class="mr-2 text-white mb-3" size="xlarge" />

      <span class="text-primary">Upload contact photo</span>
      <input #fileInput type="file" multiple (change)="onFileSelected($event)" hidden accept="image/*" />
    </div>
  } @else {
    <div
      class="flex justify-content-center align-items-center w-13rem h-12rem relative overflow-hidden custom-border-radius"
    >
      @if (userImage.includes('contacts')) {
        <img [src]="environment.cloudfrontBaseUrl + userImage" class="w-full h-full bg-cover" alt="" />
      } @else {
        <img [src]="userImage" class="w-full h-full bg-cover" alt="" />
      }
      <div class="absolute bottom-0 right-0 flex gap-2 p-1">
        <div
          class="border-circle w-2rem h-2rem flex justify-content-center bg-white align-items-center cursor-pointer"
          (click)="handleClearImage()"
        >
          <fa-icon [icon]="'x'" size="lg" />
        </div>
        <div
          class="border-circle w-2rem h-2rem flex justify-content-center bg-white align-items-center cursor-pointer"
          (click)="triggerFileUpload(fileInput)"
        >
          <input #fileInput type="file" multiple (change)="onFileSelected($event)" hidden accept="image/*" />
          <fa-icon [icon]="'camera'" size="lg" />
        </div>
      </div>
    </div>
  }
</div>
