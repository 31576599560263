<div class="email-detail px-4 py-2 border-bottom-1 border-top-1 bg-alert-50">
  <!-- From -->

  <div class="email-detail__field">
    <span>From:</span>
    <strong class="ml-1">{{ emailDetails()?.fromAliasName }}</strong>
    <span class="email-detail__email">&lt;{{ emailDetails()?.from }}&gt;</span>
    <span>{{ ', ' }}{{ formatDate(emailDetails()?.date || '') }}</span>
  </div>

  <div class="email-detail__field">
    <span>To:</span>
    <strong class="ml-1">{{ emailDetails()?.to }}</strong>
    <fa-icon
      icon="caret-down"
      class="cursor-pointer"
      (click)="op.toggle($event)"
      pTooltip="Show details"
      tooltipPosition="bottom"
      tooltipStyleClass="text-xs"
    ></fa-icon>
  </div>

  <!-- OverlayPanel -->
  <p-overlayPanel #op [style]="{ width: '450px' }">
    <app-email-detils-overlay-panel [emailDetails]="emailDetails()" />
  </p-overlayPanel>
</div>
