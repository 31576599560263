import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Button } from 'primeng/button';
import { CountrySelectComponent, OButtonDirective } from 'o-suite-lib';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { NgClass } from '@angular/common';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { allContactsStructure } from '@app/pages/my-contacts/all-contacts/all-contacts.constant';
import { UploadProfilePicComponent } from '@app/pages/my-contacts/upload-profile-pic/upload-profile-pic.component';
import {
  IApiResponse,
  IContactsData,
  ICountryDataWithPhoneNumber,
  IStatesData,
} from '@app/shared/models';
import { ContactsFacade } from '@app/pages/my-contacts/services/contacts.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, debounceTime, distinctUntilChanged, mergeMap, of, tap } from 'rxjs';

@Component({
  selector: 'app-edit-contact-dialog',
  standalone: true,
  imports: [
    DialogModule,
    Button,
    OButtonDirective,
    ReactiveFormsModule,
    InputTextModule,
    NgClass,
    InputGroupModule,
    InputGroupAddonModule,
    CountrySelectComponent,
    DropdownModule,
    CalendarModule,
    InputNumberModule,
    UploadProfilePicComponent,
  ],
  templateUrl: './edit-contact-dialog.component.html',
  styleUrl: './edit-contact-dialog.component.scss',
})
export class EditContactDialogComponent implements OnInit {
  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);
  private contactsFacade = inject(ContactsFacade);

  @Input('visible') visible: boolean = false;
  @Input('contact') set setContact(value: any) {
    this.contact = value;
    this.getCountriesList();
  }
  @Output() visibleChange = new EventEmitter<boolean>();

  contact!: IContactsData;
  editContactForm: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    jobTitle: [''],
    companyName: [''],
    alternateEmailId: ['', [Validators.required, Validators.email]],
    omailEmailId: [''],
    countryId: ['', Validators.required],
    countryCode: [''],
    contactNumber: [null],
    dateOfBirth: [''],
    stateId: [''],
    zipcode: [''],
    address: [''],
    contactPic: [''],
    favStatus: [''],
    badgeId: [0],
    contactId: 0,
    countryCallCode: [''],
  });
  submitted: boolean = false;
  contactImageFile: File | null = null;
  countryList: ICountryDataWithPhoneNumber[] = [];
  contactPic: string = '';
  userImageUrl: string = '';
  country: FormControl<any> = new FormControl(null);
  maxDate: Date = new Date();
  isSelectedStateLoading: boolean = false;

  states: IStatesData[] = [];

  ngOnInit() {
    this.editContactForm
      .get('countryId')
      ?.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((value) => {
          this.isSelectedStateLoading = true;
          if (value) {
            return value.id;
          }
          return of(false);
        }),
        mergeMap((value) => {
          if (value) {
            return this.contactsFacade.getStatesByCountryId(value.id);
          }
          return of(null);
        })
      )
      .subscribe(
        (res: IApiResponse<IStatesData[]> | null) => {
          if (res && res.body.length > 0) {
            this.states = res.body;
          }
          this.isSelectedStateLoading = false;
        },
        (error) => {
          this.isSelectedStateLoading = false;
        }
      );

    this.editContactForm
      .get('alternateEmailId')
      ?.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        mergeMap((email) => {
          if (email) {
            return this.getContactInfoByAlternativeEmail(email);
          }
          return of(null);
        })
      )
      .subscribe(() => {});
  }

  getCountriesList() {
    this.contactsFacade.getCountriesList().subscribe((countries: ICountryDataWithPhoneNumber[] | null) => {
      if (countries) {
        this.countryList = countries;
        this.initializeContactForm();
      }
    });
  }

  initializeContactForm(): void {
    this.country.valueChanges.subscribe((value: any) => {
      if (value) {
        this.editContactForm.get('countryCallCode')?.setValue(value.number);
      }
    });

    if (this.contact && allContactsStructure) {
      for (let contactKey of allContactsStructure) {
        this.editContactForm.get(contactKey?.key)?.setValue(this.contact[contactKey?.key]);
      }
      this.editContactForm.get('contactId')?.setValue(this.contact.contactId);

      let country = this.countryList.find((country) => country.id === this.contact.countryId);
      this.editContactForm.get('countryId')?.setValue(country);
      this.editContactForm.get('countryCallCode')?.setValue(this.contact.countryCallCode);

      if (this.editContactForm.get('contactNumber')?.value === '') {
        this.editContactForm.get('contactNumber')?.setValue(null);
      }

      const selectedCountryCallCode = this.countryList.find(
        (country) => country.number === this.contact.countryCallCode
      );
      this.country.setValue(selectedCountryCallCode);

      this.contactPic = this.contact['contactPic'];
      this.editContactForm.get('stateId')?.setValue(this.contact.stateId);
      this.getStatesByCountryId();

      const alternateEmailId = this.editContactForm.get('alternateEmailId')?.value;

      this.getContactInfoByAlternativeEmail(alternateEmailId).subscribe(() => {});
    }
  }

  getContactInfoByAlternativeEmail(email: string) {
    return this.contactsFacade.getContactInfoByAlternativeEmail(email).pipe(
      catchError((error) => {
        this.editContactForm.get('omailEmailId')?.setValue('');
        return of(null);
      }),
      tap((res) => {
        if (res) this.editContactForm.get('omailEmailId')?.setValue(res?.data?.omailEmailId);
      })
    );
  }

  getStatesByCountryId() {
    this.isSelectedStateLoading = true;
    const countryId = this.editContactForm.get('countryId')?.value;
    const stateId = this.editContactForm.get('stateId')?.value;
    this.contactsFacade
      .getStatesByCountryId(countryId?.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (res) => {
          if (res.body.length > 0) {
            this.states = res.body;
            const selectedState = this.states.find((state) => state.id === stateId);
            this.editContactForm.get('stateId')?.setValue(selectedState);
          }
          this.isSelectedStateLoading = false;
        },
        (error) => {
          this.isSelectedStateLoading = false;
        }
      );
  }

  onHide() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  onSubmitEdit() {
    this.submitted = true;

    if (this.editContactForm.valid && !this.contactsFacade.isEditLoading$()) {
      let fd: FormData = new FormData();
      fd.append(
        'contact',
        JSON.stringify({
          ...this.editContactForm.value,
          countryId: this.editContactForm.get('countryId')?.value?.id,
          stateId: this.editContactForm.get('stateId')?.value?.id ? this.editContactForm.get('stateId')?.value?.id : '',
          contactNumber: this.editContactForm.get('contactNumber')?.value
            ? this.editContactForm.get('contactNumber')?.value?.toString()
            : '',
          dateOfBirth: this.editContactForm.get('dateOfBirth')?.value
            ? this.contactsFacade.formatDate(this.editContactForm.get('dateOfBirth')?.value)
            : '',
          contactPic: !this.isImageCleared ? this.contact.contactPic ?? '' : ''
        })
      );
      fd.append('alternateEmailId', this.editContactForm.get('alternateEmailId')?.value);

      if (this.contactImageFile) {
        fd.append('file', this.contactImageFile);
      }
      console.log({
        fd:  JSON.parse( fd.get('contact') as string),
        userImageUrl: this.userImageUrl,
      });

      this.contactsFacade.editContact(fd, this.userImageUrl, this.editContactForm.get('countryId')?.value);
    }
  }

  isImageCleared = false;
  userImageChange(file?: File) {
    this.isImageCleared = !file;

    this.contactImageFile = file!;
  }

  userImageUrlChange(userImageUrl: string) {
    this.userImageUrl = userImageUrl;
  }

  isEditLoading = this.contactsFacade.isEditLoading$;
}
