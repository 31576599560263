<div class="create-email bg-white-100" [formGroup]="emailForm">
  <!-- Header -->
  <div class="create-email__header flex justify-content-between align-items-center px-3 border-bottom-1">
    <div class="flex align-items-center gap-3">
      <h3 class="create-email__title font-bold m-0 text-color-tertiary-500">{{ emailTitle }}</h3>
      @if (isEmailReplied()) {
        <div class="mt-2" pTooltip="Mark as Priority" tooltipPosition="bottom" tooltipStyleClass="text-xs">
          @if (loading()) {
            <p-progressSpinner
              styleClass="w-1rem h-1rem"
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          } @else {
            <app-priority-icon [highPriority]="highPriority" (click)="toggleFavorite($event)" />
          }
        </div>
      }
    </div>
    @if (!isEmailReplied()) {
      <fa-icon
        icon="xmark"
        class="create-email__close-icon cursor-pointer text-color-danger-500 border-circle border-1 flex justify-content-center align-items-center"
        (click)="onClose()"
      ></fa-icon>
    }
  </div>

  <!-- Subject Field -->
  @if (!isEmailReplied()) {
    <div class="create-email__field flex justify-content-between align-items-center px-3 border-bottom-1">
      <div class="flex align-items-center flex-1">
        <label for="subject" class="create-email__label font-normal text-color-tertiary-500">Subject:</label>
        <input
          id="subject"
          type="text"
          formControlName="subject"
          placeholder="Enter the subject"
          class="create-email__input flex-grow-1 mt-1"
        />
      </div>
      <app-priority-icon [highPriority]="highPriority" (click)="changePriority()" />
    </div>

    <!-- To Field -->
    <app-email-fields [emailForm]="emailForm" [action]="action" [draft]="draft()" />
  }
</div>
