<p-confirmPopup [draggable]="false" [key]="'custom'" [styleClass]="'view-event-popup'">
  <ng-template pTemplate="content">
    <div class="flex flex-column w-full">
      <div class="close-icon-wrapper flex w-full justify-content-end align-items-center">
        @if (organizer.inviteeEmailId === profileEmailId) {
          @if (!isBeforeNow(event.startTime)) {
            <img
              [ngSrc]="assetUrl('icons/edit.svg')"
              width="20"
              height="20"
              pTooltip="Edit event"
              tooltipPosition="bottom"
              alt="Edit event"
              class="mr-4 cursor-pointer delete-edit-buttons"
              (click)="editEvent()"
            />
          }
          @if (!isCancelEventLoading().value) {
            <img
              [ngSrc]="assetUrl('icons/trash.svg')"
              width="18"
              height="18"
              pTooltip="Delete event"
              tooltipPosition="bottom"
              alt="Delete event"
              class="mr-4 cursor-pointer delete-edit-buttons"
              (click)="deleteEvent()"
            />
          } @else {
            <p-progressSpinner
              styleClass="delete-loader mr-4"
              strokeWidth="4"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          }
        }
        <fa-icon
          icon="x"
          class="close-icon border-color-denim-gray-20 border-1 border-circle cursor-pointer"
          (click)="closeViewEvent()"
          size="xs"
        ></fa-icon>
      </div>

      <div class="view-event flex flex-column w-full gap-4 px-3 mb-2 mt-3 pb-0">
        <div class="flex flex-column w-full">
          <p class="text-2xl text-color-tertiary-500 m-0 mb-2 font-semibold">{{ event.sub }}</p>
          <p class="text-base text-color-tertiary-500 m-0 font-medium mb-3">
            {{ event.eventDate | date: 'EEEE, MMM d' }} &nbsp; {{ event.startTime | date: 'h:mm a' }} -
            {{ event.endTime | date: 'h:mm a' }}
          </p>

          @if (!!event.meetingLink) {
            <p-button
              o-button
              [oButtonSeverity]="'success'"
              label="Join with OMeet link"
              styleClass="oconnect-btn mt-2"
              class="w-17rem h-2rem"
              (onClick)="openOmeetLink(event.meetingLink)"
            ></p-button>
            <p class="color-denim-gray-90 ml-2 mt-5 text-sm">{{ event.meetingLink }}</p>
          }
        </div>

        <div class="organizer-container flex flex-column w-full">
          <div class="flex flex-row align-items-center">
            <img [ngSrc]="assetUrl('icons/user.svg')" width="13" height="16" alt="organizer" />
            <p class="m-0 ml-3 text-color-tertiary-500 text-lg font-medium">Organizer</p>
          </div>
          <div class="flex flex-row align-items-center mt-3">
            <img [ngSrc]="assetUrl('icons/profile-avatar.svg')" width="30" height="30" alt="avatar" />

            <img
              [ngSrc]="assetUrl('icons/approved-badge.svg')"
              width="16"
              height="16"
              alt="approved"
              class="-ml-2 mt-3"
            />
            <p class="m-0 ml-2 text-lg text-color-tertiary-500 font-medium">
              {{ organizer.inviteeEmailId.split('@')[0].replace('.', ' ') }}
            </p>
          </div>
        </div>

        <div class="guests-container flex flex-column w-full mt-3">
          <div class="flex flex-row align-items-center">
            <img [ngSrc]="assetUrl('icons/users.svg')" width="20" height="16" alt="organizer" />
            <p class="m-0 ml-3 text-color-tertiary-500 text-lg font-medium">
              {{ event.invitees.length }} Guest{{ event.invitees.length > 1 ? 's' : '' }}
            </p>
          </div>

          @for (invitee of event.invitees; track invitee.inviteeId) {
            <div class="flex flex-row align-items-center mt-3">
              <img [ngSrc]="assetUrl('icons/profile-avatar.svg')" width="30" height="30" alt="avatar" />

              @if (invitee.attendStatus) {
                <img
                  [ngSrc]="assetUrl(attendingStatusMap[invitee.attendStatus].src || attendingStatusMap['PENDING'].src)"
                  [alt]="attendingStatusMap[invitee.attendStatus].alt || attendingStatusMap['PENDING'].alt"
                  width="16"
                  height="16"
                  class="-ml-2 mt-3"
                />
              }
              <p class="m-0 ml-2 text-lg text-color-tertiary-500 font-medium">
                {{ invitee?.inviteeEmailId }}
              </p>
            </div>
          }
        </div>

        @if (!!this.event.description) {
          <div class="notes-container flex flex-row align-items-baseline w-full">
            <img [ngSrc]="assetUrl('icons/notes.svg')" width="18" height="18" alt="notes" />
            <div [innerHTML]="sanitizedHtml" class="text-lg text-color-tertiary-500 m-0 ml-3"></div>
          </div>
        }

        <div>
          @if (!isBeforeNow(event.endTime) && organizer.inviteeEmailId !== profileEmailId) {
            <p-divider />

            <div class="popup-footer w-full flex flex-row align-items-center justify-content-end mt-4">
              <p class="text-lg text-color-tertiary-500 m-0 mr-4">Attending?</p>
              <p-button
                label="Yes"
                [outlined]="currentAttendStatus !== 'ATTENDING'"
                [loading]="isLoadingChangeAttendanceStatus === 'ATTENDING'"
                [disabled]="!!isLoadingChangeAttendanceStatus"
                styleClass="attending-buttons w-5rem h-3rem mr-2"
                (onClick)="sendAttendStatus('ATTENDING')"
              ></p-button>
              <p-button
                o-button
                [outlined]="currentAttendStatus !== 'REJECTING'"
                [loading]="isLoadingChangeAttendanceStatus === 'REJECTING'"
                [disabled]="!!isLoadingChangeAttendanceStatus"
                [oButtonSeverity]="'danger'"
                label="No"
                styleClass="attending-buttons w-5rem h-3rem"
                (onClick)="sendAttendStatus('REJECTING')"
              ></p-button>
            </div>
          }
        </div>
      </div>
    </div>
  </ng-template>
</p-confirmPopup>

@if (isShowEditEvent) {
  <app-edit-event-modal
    [event]="event"
    [(isShowEditEvent)]="isShowEditEvent"
    [profileEmailId]="profileEmailId"
    (eventUpdated)="closeViewEvent()"
  ></app-edit-event-modal>
}
