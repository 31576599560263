<div class="email-fields">
  <div class="field flex justify-content-between align-items-center border-bottom-1 px-3 py-2 m-0">
    <app-email-field
      label="To"
      [emails]="to"
      placeholder="Enter email address"
      (emailsChange)="onEmailsChange($event, 'to')"
      class="flex-1"
      [(isEditing)]="isEditing"
    ></app-email-field>

    <span class="email-fields__links ml-2">
      @if (!showCC) {
        <span
          href="#"
          (click)="toggleField('cc', true); $event.preventDefault()"
          class="cursor-pointer text-color-primary-500"
        >
          CC
        </span>
      }
      @if (!showBCC) {
        <span
          href="#"
          (click)="toggleField('bcc', true); $event.preventDefault()"
          class="cursor-pointer text-color-primary-500 ml-3"
        >
          BCC
        </span>
      }
    </span>
  </div>

  @if (showCC) {
    <div class="field flex justify-content-between m-0 align-items-center border-bottom-1 px-3 py-2">
      <app-email-field
        label="CC"
        [emails]="cc"
        placeholder="Enter CC address"
        [removable]="true"
        (emailsChange)="onEmailsChange($event, 'cc')"
        (remove)="toggleField('cc', false)"
        class="flex-1"
        [(isEditing)]="isEditing"
      ></app-email-field>
    </div>
  }

  @if (showBCC) {
    <div class="field flex justify-content-between m-0 align-items-center border-bottom-1 px-3 py-2">
      <app-email-field
        label="BCC"
        [emails]="bcc"
        placeholder="Enter BCC address"
        [removable]="true"
        (emailsChange)="onEmailsChange($event, 'bcc')"
        (remove)="toggleField('bcc', false)"
        class="flex-1"
      ></app-email-field>
    </div>
  }
</div>
