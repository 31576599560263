import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { IconsModule } from '@app/shared/modules/icons.module';
import { CreateFolderComponent } from '@app/components/mail/create-folder/create-folder.component';
import { Folder } from '@app/shared/models/omail';
import { NavigationStart, Router } from '@angular/router';
import { MAILBOX_ROUTES } from '@app/constants';
import { EmailService } from '@app/services/Email.service';
import { OToastV2Service } from 'o-suite-lib';
import { environment } from '@src/environments/environment';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-custom-tree',
  standalone: true,
  imports: [CommonModule, IconsModule, CreateFolderComponent, ProgressSpinnerModule],
  templateUrl: './custom-tree.component.html',
  styleUrl: './custom-tree.component.scss',
})
export class CustomTreeComponent implements OnInit {
  @Input() treeData: Folder[] = [];
  showDialog = false;
  isExpanded = false;
  router = inject(Router);
  emailService = inject(EmailService);
  toaster = inject(OToastV2Service);
  destroyRef = inject(DestroyRef);
  loadingDeletedFolder = '';
  folderSelected: Folder | undefined;
  socketService = inject(SocketV2Service);

  ngOnInit(): void {
     this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!event!.url.includes('folder')) {
            this.folderSelected = undefined;
        }
      }
    });
  }

  toggleFolder() {
    this.isExpanded = !this.isExpanded;
  }

  handleClickOnFolder(folder: Folder) {
    this.router.navigate([MAILBOX_ROUTES.MAIL, MAILBOX_ROUTES.Folder], {
      queryParams: { fn: folder.folderName },
    });

    this.setSelectedFolder(folder);
  }

  showCreateFolderDialog(event: MouseEvent, folder?: Folder) {
    event.stopPropagation();
    this.showDialog = true;
    this.folderSelected = folder;
  }

  deleteFolder(event: MouseEvent, folder: Folder) {
    event.stopPropagation();
    this.loadingDeletedFolder = folder.folderName;
    this.emailService
      .deleteFolder({
        folderName: folder.folderName,
        isRestore: false,
      })
      .subscribe({
        next: (res) => {
          this.toaster.add({
            severity: 'success',
            summary: res.message,
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.socketService.updateDynamicFolder(folder, 'delete');
          this.loadingDeletedFolder = '';
        },
        error: (err) => {
          this.toaster.add({
            severity: 'error',
            summary: err?.error?.message || 'Error while deleting folder.',
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
          this.loadingDeletedFolder = '';
        },
      });
  }

  private setSelectedFolder(folder: Folder) {
    this.folderSelected = folder;
  }
}
