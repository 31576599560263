import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { IContactsData } from '@app/shared/models/contacts.model';
import { ContactsFacade } from '@app/pages/my-contacts/services/contacts.facade';
import { IconsModule } from '@app/shared/modules/icons.module';
import { environment } from '@src/environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-all-contacts-sidebar',
  standalone: true,
  imports: [CheckboxModule, FormsModule, ReactiveFormsModule, DividerModule, IconsModule, ProgressSpinnerModule],
  templateUrl: './all-contacts-sidebar.component.html',
  styleUrl: './all-contacts-sidebar.component.scss',
})
export class AllContactsSidebarComponent implements OnInit {
  selectedContact!: IContactsData;

  contacts: IContactsData[] = [];

  private readonly defaultAvatar = 'https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png';
  private contactsFacade = inject(ContactsFacade);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.contactsFacade
      .getAllContactsData()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((contacts: IContactsData[] | null) => {
        if (contacts) {
          this.contacts = contacts;
          this.selectContact(this.contacts[0]);
        }
      });
  }

  selectContact(contact: IContactsData) {
    this.selectedContact = contact;
    this.contactsFacade.setSelectedContact(this.selectedContact);
  }

  getContactPic(contact: any): string {
    if (!contact?.contactPic) {
      return '';
    }

    return contact.contactPic.includes('contacts')
      ? environment.cloudfrontBaseUrl + contact.contactPic
      : contact.contactPic;
  }

  handleImageError(contact: any): void {
    contact.contactPic = this.defaultAvatar;
  }

  addRemoveContactFromFavorite(index: number) {
    this.contacts[index].isFavoriteLoading = true;
    this.contactsFacade.addRemoveContactFromFavorite(this.contacts[index]);
  }
}
