<div
  class="email-actions flex align-items-center gap-3"
  [ngClass]="{ 'email-actions--textIncluded': isTextIncluded() }"
>
  <div
    [pTooltip]="!isTextIncluded() ? 'Reply' : undefined"
    tooltipStyleClass="text-xs"
    tooltipPosition="bottom"
    class="icon text-color-tertiary-300 flex justify-content-center align-items-center cursor-pointer"
    (click)="navigateToCreateEmail($event, emailActions.REPLY)"
  >
    <fa-icon icon="reply" class=" "></fa-icon>
    @if (isTextIncluded()) {
      <span class="text-color-tertiary-500">Reply</span>
    }
  </div>

  @if (showReplyAll) {
    <div
      [pTooltip]="!isTextIncluded() ? 'Reply All' : undefined"
      tooltipStyleClass="text-xs"
      tooltipPosition="bottom"
      class="icon text-color-tertiary-300 flex justify-content-center align-items-center cursor-pointer"
      (click)="navigateToCreateEmail($event, emailActions.REPLY_ALL)"
    >
      <fa-icon icon="reply-all" class=" "></fa-icon>
      @if (isTextIncluded()) {
        <span class="text-color-tertiary-500">Reply All</span>
      }
    </div>
  }
  <div
    [pTooltip]="!isTextIncluded() ? 'Forward' : undefined"
    tooltipStyleClass="text-xs"
    tooltipPosition="bottom"
    class="icon text-color-tertiary-300 flex justify-content-center align-items-center cursor-pointer"
    (click)="navigateToCreateEmail($event, emailActions.FORWARD)"
  >
    <fa-icon icon="share" class=" "></fa-icon>
    @if (isTextIncluded()) {
      <span class="text-color-tertiary-500">Forward</span>
    }
  </div>
</div>
