import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, input, signal, SimpleChanges } from '@angular/core';
import { IconsModule } from '@app/shared/modules/icons.module';
import { TooltipModule } from 'primeng/tooltip';
import { PriorityIconComponent } from '@app/shared/svg/priority-icon/priority-icon.component';
import { IImportantEmailPayload, IReadEmailResponse } from '@app/shared/models/omail';
import { EmailService } from '@app/services/Email.service';
import { TextToSpeechComponent } from '../../shared/text-to-speech/text-to-speech.component';
import { EmailFormatterService } from '@app/services/email-format.service';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { OToastV2Service } from 'o-suite-lib';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { environment } from '@src/environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ReplysComponent } from '../../shared/replys/replys.component';
import { EmailContextMenuComponent } from '../../shared/email-context-menu/email-context-menu.component';

@Component({
  selector: 'app-email-header',
  standalone: true,
  imports: [
    CommonModule,
    OverlayPanelModule,
    TooltipModule,
    IconsModule,
    PriorityIconComponent,
    TextToSpeechComponent,
    ProgressSpinnerModule,
    TieredMenuModule,
    ReplysComponent,
    EmailContextMenuComponent,
  ],
  templateUrl: './email-header.component.html',
  styleUrl: './email-header.component.scss',
})
export class EmailHeaderComponent {
  showActions = input(true);
  emailService = inject(EmailService);
  emailFormatterService = inject(EmailFormatterService);
  socketService = inject(SocketV2Service);
  toaster = inject(OToastV2Service);
  private destroyRef = inject(DestroyRef);

  emailDetails = input<IReadEmailResponse>();
  loading = signal(false);

  highPriority = signal<boolean>(false);
  formattedEmail: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['emailDetails']) {
      this.formattedEmail = this.emailFormatterService.formatEmailForReading(this.emailDetails());
      this.highPriority.set(this.emailDetails()?.important ?? false);
    }
  }

  toggleFavorite(event: Event) {
    event.stopPropagation();
    if (this.emailDetails() != undefined) {
      const payload: IImportantEmailPayload = {
        msgs: [this.emailDetails()?.sno!],
        folder: this.emailDetails()?.folderName || '',
        important: !this.emailDetails()?.important,
      };
      this.loading.set(true);
      this.emailService
        .markAsFavouriteOrImportant(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.socketService.updateEmailDetails({
              messageId: this.emailDetails()?.messageId,
              folderName: this.emailDetails()?.folderName,
              important: !this.highPriority,
            });
            this.highPriority.set(!this.highPriority);
            this.loading.set(false);
          },
          error: (err) => {
            this.loading.set(false);
            this.toaster.add({
              severity: 'error',
              summary: err?.error?.message || 'Error marking email as important',
              icon: environment.publicPath + '/assets/icons/toast/error.svg',
            });
          },
        });
    }
  }
}
