import { formatDate } from '@app/utils/format-date';
import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { Email, IReadEmailResponse } from '@app/shared/models/omail';
import { IconsModule } from '@app/shared/modules/icons.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { EmailDetilsOverlayPanelComponent } from '../../shared/email-detils-overlay-panel/email-detils-overlay-panel.component';
@Component({
  selector: 'app-email-detail',
  standalone: true,
  imports: [CommonModule, OverlayPanelModule, IconsModule, TooltipModule, EmailDetilsOverlayPanelComponent],
  templateUrl: './email-detail.component.html',
  styleUrl: './email-detail.component.scss',
})
export class EmailDetailComponent {
  emailDetails = input<IReadEmailResponse>();

  showOverlay(event: MouseEvent, overlayPanel: any) {
    overlayPanel.toggle(event);
  }
  formatDate(date: string | number): string {
    return formatDate(date, true);
  }
}
