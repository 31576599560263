import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, input, Input, output, signal, SimpleChanges } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@app/shared/modules/icons.module';
import { PriorityIconComponent } from '@app/shared/svg/priority-icon/priority-icon.component';
import { IImportantEmailPayload, IReadEmailResponse } from '@app/shared/models/omail';
import { EmailService } from '@app/services/Email.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@src/environments/environment';
import { EmailFieldsComponent } from '../../shared/email-fields/email-fields.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { OToastV2Service } from 'o-suite-lib';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { EMAIL_ACTIONS } from '@app/constants';

@Component({
  selector: 'app-create-email-header',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    ReactiveFormsModule,
    PriorityIconComponent,
    ProgressSpinnerModule,
    EmailFieldsComponent,
  ],
  templateUrl: './create-email-header.component.html',
  styleUrl: './create-email-header.component.scss',
})
export class CreateEmailHeaderComponent {
  private emailService = inject(EmailService);
  private destroyRef = inject(DestroyRef);
  private toaster = inject(OToastV2Service);
  private socketService = inject(SocketV2Service);

  @Input({ required: true }) action!: string;
  @Input() emailForm!: FormGroup;
  close = output();

  highPriority: boolean = false;
  draft = input<IReadEmailResponse>();
  isEmailReplied = input<boolean>(false);

  changePriority(): void {
    this.highPriority = !this.highPriority;
    this.emailForm.get('priority')?.setValue(this.highPriority ? 1 : 0);
  }

  emailTitle: string = 'New Email';
  subject: string | undefined = '';
  loading = signal(false);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['action']) {
      const emailDetails = this.emailService.getEmailDetails();
      if (this.action === EMAIL_ACTIONS.DRAFT) {
        this.emailTitle = 'Draft Email';
        this.subject = this.draft()?.subject;
      } else if (this.action !== EMAIL_ACTIONS.NEW_EMAIL) {
        this.emailTitle = emailDetails?.subject;
        this.subject = emailDetails?.subject;
        this.emailForm.get('subject')?.setValue(this.subject);
      } else {
        this.emailTitle = 'New Email';
      }
    }
  }
  onClose() {
    this.close.emit();
  }

  toggleFavorite(event: Event) {
    event.stopPropagation();
    const emailDetails = this.emailService.getEmailDetails();
    if (emailDetails != undefined) {
      const payload: IImportantEmailPayload = {
        msgs: [emailDetails?.sno!],
        folder: emailDetails?.folderName || '',
        important: !emailDetails?.important,
      };
      this.loading.set(true);
      this.emailService
        .markAsFavouriteOrImportant(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.socketService.updateEmailDetails({
              messageId: emailDetails?.messageId,
              folderName: emailDetails?.folderName,
              important: !this.highPriority,
            });
            this.highPriority = !this.highPriority;
            this.loading.set(false);
          },
          error: (err) => {
            this.loading.set(false);
            this.toaster.add({
              severity: 'error',
              summary: err?.error?.message,
              icon: environment.publicPath + '/assets/icons/toast/error.svg',
            });
          },
        });
    }
  }
}
