<div class="popup-content flex flex-column gap-3">
  <div class="flex gap-2">
    <span class="text-color-tertiary-300">"From":</span>
    <span class="text-color-tertiary-500 font-semibold">{{ emailDetails()?.from }}</span>
  </div>

  <div class="flex gap-2">
    <span class="text-color-tertiary-300">To:</span>
    <span class="text-color-tertiary-500 font-semibold">
      {{ emailDetails()?.to }}
    </span>
  </div>
  @if ((emailDetails()?.cc?.length ?? 0) > 0) {
    <div class="flex gap-2">
      <span class="text-color-tertiary-300">CC:</span>
      <span class="text-color-tertiary-500 font-semibold">{{ emailDetails()?.cc }}</span>
    </div>
  }
  <div class="flex gap-2">
    <span class="text-color-tertiary-300">"Date":</span>
    <span class="text-color-tertiary-500 font-semibold">{{ formatDate(emailDetails()?.date || '') }}</span>
  </div>
  <div class="flex gap-2">
    <span class="text-color-tertiary-300">"Subject":</span>
    <span class="text-color-tertiary-500 font-semibold">{{ emailDetails()?.subject }}</span>
  </div>
</div>
