<div class="email-header px-3 border-bottom-1 flex justify-content-between align-items-center">
  <div class="flex items-center gap-1 align-items-center">
    <p-checkbox
      [(ngModel)]="isChecked"
      [binary]="true"
      [checkboxIcon]="'pi pi-minus'"
      inputId="ny"
      (onChange)="toggleSelectAll()"
    ></p-checkbox>
    <!-- Dynamic label -->
    <label for="ny" class="ml-2 text-sm">
      {{ getLabelText() }}
    </label>
  </div>
  @if (selectedEmails.length > 0) {
    <div class="flex items-center gap-1">
      @if (loading().read) {
        <p-progressSpinner
          styleClass="w-1rem h-1rem"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          class="email-header__icon text-primary flex justify-content-center align-items-center text-sm shadow-1 bg-white-100 border-round-sm border-gray-200 cursor-pointer"
        />
      } @else {
        <fa-icon
          (click)="markAsRead()"
          icon="envelope-open"
          pTooltip="Mark as read"
          tooltipPosition="bottom"
          tooltipStyleClass="text-xs"
          class="email-header__icon flex justify-content-center align-items-center text-color-primary-500 text-sm shadow-1 bg-white-100 border-round-sm border-gray-200 cursor-pointer"
        ></fa-icon>
      }
      @if (isMoveToFolderOptionVisible) {
        <div
          class="email-header__icon flex justify-content-center align-items-center text-color-primary-500 text-sm shadow-1 bg-white-100 border-round-sm border-gray-200 cursor-pointer"
          (click)="showMoveFoldersDialog = true"
        >
          <img
            [src]="assetUrl('icons/move-folder.svg')"
            alt="move-folder"
            pTooltip="Move to folder"
            tooltipPosition="bottom"
            tooltipStyleClass="text-xs"
          />
        </div>
      }
      @if (loading().important) {
        <p-progressSpinner
          styleClass="w-1rem h-1rem"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          class="email-header__icon text-primary flex justify-content-center align-items-center text-sm shadow-1 bg-white-100 border-round-sm border-gray-200 cursor-pointer"
        />
      } @else {
        <div
          (click)="moveToImportant()"
          pTooltip="Add to priority"
          tooltipPosition="bottom"
          tooltipStyleClass="text-xs"
          class="email-header__icon text-primary flex justify-content-center align-items-center text-sm shadow-1 bg-white-100 border-round-sm border-gray-200 cursor-pointer"
        >
          <app-priority-icon color="#00ACFB" />
        </div>
      }
      @if (loading().delete) {
        <p-progressSpinner
          styleClass="w-1rem h-1rem"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          class="email-header__icon text-primary flex justify-content-center align-items-center text-sm shadow-1 bg-white-100 border-round-sm border-gray-200 cursor-pointer"
        />
      } @else {
        <fa-icon
          (click)="moveToTrash()"
          icon="trash-can"
          pTooltip="Move to trash"
          tooltipPosition="bottom"
          tooltipStyleClass="text-xs"
          class="email-header__icon text-primary flex justify-content-center align-items-center text-sm shadow-1 bg-white-100 border-round-sm border-gray-200 cursor-pointer"
        ></fa-icon>
      }
    </div>
  }
</div>
@if (showMoveFoldersDialog) {
  <app-move-folder-modal
    [(showDialog)]="showMoveFoldersDialog"
    [selectedEmails]="selectedEmails"
    (unselectAllEmails)="toggleSelectAll()"
    (showCreateFolderDialog)="displayCreateFolderModal()"
  ></app-move-folder-modal>
}

@if (showCreateFolderDialog) {
  <app-create-folder [(display)]="showCreateFolderDialog" (showMoveFoldersDialog)="displayMoveFoldersDialog()" />
}
