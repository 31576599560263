import { inject, Injectable, signal } from '@angular/core';
import { CalendarState } from '@app/pages/calendar/services/calendar.state';
import { CalendarApi } from '@app/pages/calendar/services/calendar.api';
import {
  IApiResponse,
  IApiResponseData,
  ICalendarDataPayload,
  ICalendarEvent,
  ICalendarEventPayload,
  ICalendarEventsData,
  IEventsApiResponse,
} from '@app/shared/models';
import { debounceTime, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IMyProfileData } from '@app/shared/models/settings.model';
import { environment } from '@src/environments/environment';
import { OToastV2Service } from 'o-suite-lib';

@Injectable({
  providedIn: 'root',
})
export class CalendarFacade {
  private calendarState = inject(CalendarState);
  private calendarApi = inject(CalendarApi);
  oToastV2Service = inject(OToastV2Service);

  isCreateEventLoading$ = signal({ value: false, isError: true });
  isEditEventLoading$ = signal({ value: false, isError: true });
  isCancelEventLoading$ = signal({ value: false, isError: true });
  isCreateEventLoading = this.isCreateEventLoading$.asReadonly();
  isEditEventLoading = this.isEditEventLoading$.asReadonly();
  isCancelEventLoading = this.isCancelEventLoading$.asReadonly();

  getProfileInfo(): Observable<IApiResponseData<IMyProfileData>> {
    return this.calendarApi.getProfileInfo();
  }

  getCalendarEventsData(data: ICalendarDataPayload) {
    this.calendarApi
      .getCalendarEventsData(data)
      .pipe(debounceTime(500))
      .subscribe((res: IEventsApiResponse<ICalendarEventsData[]>) => {
        this.calendarState.setCalendarEvents(res?.events);
      });
  }

  setCalendarEvents(events: ICalendarEventsData[]) {
    this.calendarState.setCalendarEvents(events);
  }

  getCalendarEvents(): Observable<ICalendarEventsData[] | null> {
    return this.calendarState.getCalendarEvents();
  }

  getEventById(id: string, startDate: string, endDate: string): Observable<ICalendarEvent> {
    return this.calendarApi.getEventById(id, startDate, endDate);
  }

  deleteEvent(id: string, startDate: string, endDate: string) {
    return this.calendarApi.deleteEvent(id, startDate, endDate);
  }

  setAttendance(attendStatus: string, eventId: number) {
    return this.calendarApi.setAttendance(attendStatus, eventId);
  }

  CreateEvent(fd: FormData) {
    const action = fd.get('action') as string | null;
    const loadingMap = {
      '0': this.isEditEventLoading$,
      '1': this.isCreateEventLoading$,
      '2': this.isCancelEventLoading$,
    };

    (loadingMap[action as keyof typeof loadingMap] || this.isCancelEventLoading$).set({ value: true, isError: false });

    return this.calendarApi.CreateEvent(fd).subscribe(
      (res: IApiResponseData<any>) => {
        const message = res?.message ?? 'Event sent!';
        this.oToastV2Service.add({
          severity: 'success',
          summary: message,
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
        });

        (loadingMap[action as keyof typeof loadingMap] || this.isCancelEventLoading$).set({
          value: false,
          isError: false,
        });
      },
      (error) => {
        (loadingMap[action as keyof typeof loadingMap] || this.isCancelEventLoading$).set({
          value: false,
          isError: true,
        });
      }
    );
  }
}
