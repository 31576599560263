<div class="flex flex-row">
  <app-calendar-sidebar
    [returnToToday]="!returnToToday"
    [currentDate]="currentDate"
    (selectedOption)="setSelectedOption($event)"
    (selectedDate)="setSelectedDate($event)"
    (createEvent)="createEvent()"
  />

  <div class="calendar-container p-3">
    <div class="demo-app-main">
      <full-calendar #calendar *ngIf="calendarVisible()" [options]="calendarOptions()">
        <ng-template #eventContent let-arg>
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </ng-template>
      </full-calendar>
    </div>
  </div>
</div>

<app-create-event-popup
  [selectedDayInfo]="selectedDayInfo"
  [profileEmailId]="profileEmailId"
  (eventCreated)="getCalendarEvents()"
></app-create-event-popup>

<app-view-event-popup
  [selectedEventInfo]="selectedEventInfo"
  (eventDeleted)="getCalendarEvents()"
></app-view-event-popup>

<!--<p-contextMenu #cm [model]="eventContextMenuItems" />-->
<p-confirmPopup styleClass="delete-event-confirm-popup" />
