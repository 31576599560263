@if (isDeleteOrMoveInProgress()) {
  <div class="ellipsis-icon cursor-auto flex justify-content-center align-items-center">
    <p-progressSpinner
      styleClass=" w-1rem h-1rem text-color-tertiary-300 "
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
    />
  </div>
} @else {
  <fa-icon
    icon="ellipsis-h"
    class="ellipsis-icon text-color-tertiary-300 flex justify-content-center align-items-center cursor-pointer"
    pTooltip="More actions"
    tooltipStyleClass="text-xs"
    tooltipPosition="bottom"
    (click)="menu.toggle($event)"
  ></fa-icon>
}
<p-tieredMenu #menu [model]="menuItems" [popup]="true"></p-tieredMenu>
@if (displayCreateFolderDialog()) {
  <app-create-folder [(display)]="displayCreateFolderDialog" />
}
