<div class="email-header flex flex-full align-items-center justify-content-between p-4">
  <div class="email-header__subject flex align-items-center gap-2">
    <span class="email-header__title font-bold text-color-tertiary-500">{{ emailDetails()?.subject }}</span>
    <div class="mt-2" pTooltip="Mark as Priority" tooltipPosition="bottom" tooltipStyleClass="text-xs">
      @if (loading()) {
        <p-progressSpinner
          styleClass="w-1rem h-1rem"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      } @else {
        <app-priority-icon [highPriority]="highPriority()" (click)="toggleFavorite($event)" />
      }
    </div>
  </div>

  <div class="email-header__actions flex align-items-center gap-3">
    <app-text-to-speech [textToRead]="formattedEmail" />
    @if (showActions()) {
      <app-replys [emailDetails]="emailDetails()" />
    }
    <app-email-context-menu [emailDetails]="emailDetails()" />
  </div>
</div>
