import { Component } from '@angular/core';

@Component({
  selector: 'app-email-details',
  standalone: true,
  imports: [],
  templateUrl: './email-details.component.html',
  styleUrl: './email-details.component.scss',
})
export class EmailDetailsComponent {
  emails = [
    {
      from: {
        name: 'Adam Smith',
        email: 'adam.smith@onfusion.com',
      },
      sentDate: 'Sunday, February 2, 2025 5:22 PM',
      to: [
        { name: 'Omair Shtayeh', email: 'omair.shtayeh@onfusion.com' },
        { name: 'Joe Adel', email: 'joe.adel@onfusion.com' },
      ],
      subject: 'Re: Subject goes here',
      body: ["We're testing the experience here", 'Ok Sir - Thanks'],
    },
    {
      from: {
        name: 'Adam Smith',
        email: 'adam.smith@onfusion.com',
      },
      sentDate: 'Sunday, February 2, 2025 5:22 PM',
      to: [
        { name: 'Omair Shtayeh', email: 'omair.shtayeh@onfusion.com' },
        { name: 'Joe Adel', email: 'joe.adel@onfusion.com' },
      ],
      subject: 'Re: Subject goes here',
      body: ["We're testing the experience here", 'Ok Sir - Thanks'],
    },
    {
      from: {
        name: 'Adam Smith',
        email: 'adam.smith@onfusion.com',
      },
      sentDate: 'Sunday, February 2, 2025 5:22 PM',
      to: [
        { name: 'Omair Shtayeh', email: 'omair.shtayeh@onfusion.com' },
        { name: 'Joe Adel', email: 'joe.adel@onfusion.com' },
      ],
      subject: 'Re: Subject goes here',
      body: ["We're testing the experience here", 'Ok Sir - Thanks'],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
