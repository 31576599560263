import { SocketV2Service } from './../../../services/socket-v2.service';
import { Component, DestroyRef, inject, input, model, output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { Button } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PasswordModule } from 'primeng/password';
import { CommonModule } from '@angular/common';
import { OButtonDirective, OToastV2Service } from 'o-suite-lib';
import { EmailService } from '@app/services/Email.service';
import { environment } from '@src/environments/environment';
import { Folder, folderParams } from '@app/shared/models/omail';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-create-folder',
  standalone: true,
  imports: [
    DialogModule,
    Button,
    OButtonDirective,
    DropdownModule,
    InputSwitchModule,
    CommonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
  ],
  templateUrl: './create-folder.component.html',
  styleUrl: './create-folder.component.scss',
})
export class CreateFolderComponent {
  socketService = inject(SocketV2Service);
  destroyRef = inject(DestroyRef);

  folder = input<Folder>();
  showMoveFoldersDialog = output<void>();
  display = model<boolean>(false);

  loading = false;
  isSubmitted = false;

  form: FormGroup;
  colors = [
    { label: 'Primary', value: 'primary' },
    { label: 'Alternative', value: 'alternative' },
    { label: 'Tertiary', value: 'tertiary' },
    { label: 'Danger', value: 'danger' },
    { label: 'Success', value: 'success' },
    { label: 'Warning', value: 'warning' },
    { label: 'Alert', value: 'alert' },
    { label: 'Info', value: 'info' },
  ];

  emailService = inject(EmailService);
  toaster = inject(OToastV2Service);

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      folderName: ['', Validators.required],
      folderColor: [null, Validators.required],
      isPasscodeEnabled: [false],
      passcode: [{ value: null, disabled: true }, Validators.required],
    });

    this.form
      .get('isPasscodeEnabled')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((enabled) => {
        if (enabled) {
          this.form.get('passcode')?.enable();
        } else {
          this.form.get('passcode')?.disable();
          this.form.get('passcode')?.reset();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['folder'] && changes['display'] && this.folder()) {
      this.form.patchValue({
        folderName: this.folder()?.folderName,
        folderColor: this.folder()?.folderColorCode,
        isPasscodeEnabled: !!this.folder()?.passCode,
        passcode: this.folder()?.passCode,
      });
    }
  }

  onCancel() {
    this.display.set(false);
    this.isSubmitted = false;
    this.form.reset();
    this.showMoveFoldersDialog?.emit();
  }

  onSave() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }

    const formData = this.form.value;
    const payload: folderParams = {
      colorCode: formData.folderColor,
      folder: this.folder()?.folderName ?? formData.folderName,
      passCode: formData.passcode || null,
      folderId: this.folder()?.folderId || 0,
      updateFolder: this.folder()?.folderName ? formData.folderName : null,
      subFolder: null,
      folderNames: null,
    };
    this.loading = true;

    if (this.folder()) {
      if (
        this.folder()?.folderName === formData.folderName &&
        this.folder()?.folderColorCode === formData.folderColor &&
        (this.folder()?.passCode == formData.passcode || (!this.folder()?.passCode && !formData.passcode))
      ) {
        this.toaster.add({
          severity: 'info',
          summary: 'No changes detected.',
          icon: environment.publicPath + '/assets/icons/toast/info.svg',
        });
        this.loading = false;
        return;
      }
    }
    this.emailService.createFolder(payload).subscribe({
      next: (res) => {
        this.toaster.add({
          severity: 'success',
          summary: `Folder ${formData.folderName} ${this.folder()?.folderName ? 'updated' : 'created'}.`,
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
        });
        this.loading = false;
        this.socketService.updateDynamicFolder({ ...res, folderColorCode: res.colorCode });
        this.onCancel();
      },
      error: (err: any) => {
        this.toaster.add({
          severity: 'error',
          summary: err?.error?.message || `Failed to ${this.folder()?.folderName ? 'update' : 'create'} folder.`,
          icon: environment.publicPath + '/assets/icons/toast/error.svg',
        });
        this.loading = false;
      },
    });
  }
}
