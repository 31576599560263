import { Component, effect, inject, input, Input, output, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CalendarModule } from 'primeng/calendar';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { IconsModule } from '@app/shared/modules/icons.module';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputNumberModule } from 'primeng/inputnumber';
import {
  ICalendarDropdownOptions,
  ICalendarEvent,
  ICalendarEventPayload,
  ICustomEventOptions,
  IDayRepeatOption,
  IGuest,
} from '@app/shared/models';
import { dayOptionsRepeat, repeatOptions } from '@app/pages/calendar/calendar.constants';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ChipsModule } from 'primeng/chips';
import { FloatLabelModule } from 'primeng/floatlabel';
import { StyleClassModule } from 'primeng/styleclass';
import { Editor, EditorModule } from 'primeng/editor';
import { CalendarFacade } from '@app/pages/calendar/services/calendar.facade';
import { emailArrayValidator, timeRangeValidator } from '@app/pages/calendar/services/calendar-validators';
import { CustomRepeatComponent } from '@app/pages/calendar/custom-repeat/custom-repeat.component';

@Component({
  selector: 'app-edit-event-modal',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    ConfirmDialogModule,
    FaIconComponent,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    DividerModule,
    IconsModule,
    ConfirmPopupModule,
    InputNumberModule,
    RadioButtonModule,
    ChipsModule,
    FloatLabelModule,
    StyleClassModule,
    EditorModule,
    CustomRepeatComponent,
  ],
  providers: [ConfirmationService, DatePipe],
  templateUrl: './edit-event-modal.component.html',
  styleUrl: './edit-event-modal.component.scss',
})
export class EditEventModalComponent {
  private fb = inject(FormBuilder);
  private confirmationService = inject(ConfirmationService);
  private calendarFacade = inject(CalendarFacade);
  private datePipe = inject(DatePipe);

  @Input('event') set setEvent(value: ICalendarEvent) {
    if (value) {
      this.event = value;
      this.editEventForm.get('eventName')?.setValue(this.event.sub);
      this.editEventForm.get('date')?.setValue(new Date(this.event.startTime));
      this.editEventForm.get('fromTime')?.setValue(new Date(this.event.startTime));
      this.editEventForm.get('toTime')?.setValue(new Date(this.event.endTime));
      this.editEventForm.get('guests')?.setValue(this.event.invitees.map((invitee) => invitee.inviteeEmailId));
      this.editEventForm.get('location')?.setValue(this.event.location);
      this.editEventForm.get('description')?.setValue(this.event.description);

      if (this.event.repeatEvent) {
        this.eventCustomOptions = {
          repeatDays: this.event.repeatDays,
          repeatEndDate: this.event.repeatEndDate,
          repeatFrequency: this.event.repeatFrequency,
          repeatInterval: Number(this.event.repeatInterval),
          repeatOccurrences: this.event.repeatOccurrences,
        };

        this.editEventForm
          .get('repeatOption')
          ?.setValue(this.repeatOptions[Number(this.event.repeatInterval) === 0 ? 2 : 3]);
      } else {
        this.editEventForm.get('repeatOption')?.setValue(this.repeatOptions[0]);
      }

      this.getEventDay();
    }
  }

  profileEmailId = input.required<string>();
  isShowEditEvent = input.required<boolean>();
  isShowEditEventChange = output<boolean>();
  eventUpdated = output<boolean>();

  customRepeatVisibility: boolean = false;
  repeatOptions: ICalendarDropdownOptions[] = repeatOptions;
  displayFormat: string = 'DD, MM d, yy';
  submitted: boolean = false;
  resetCustomOption: boolean = false;
  dayOptionsRepeat: IDayRepeatOption[] = dayOptionsRepeat;
  eventCustomOptions: ICustomEventOptions | null = null;
  event!: ICalendarEvent;
  isEditEventLoading = this.calendarFacade.isEditEventLoading;

  @ViewChild('pEditor') pEditor!: Editor;

  editEventForm: FormGroup = this.fb.group(
    {
      eventName: ['', Validators.required],
      date: [undefined, Validators.required],
      fromTime: [undefined, Validators.required],
      toTime: [undefined, Validators.required],
      guests: [[], [Validators.required, emailArrayValidator()]],
      location: [''],
      description: [''],
      repeatOption: [undefined],
      repeatEvent: [0],
      repeatDays: [[]],
      repeatInterval: [null],
      repeatEndDate: [null],
      repeatOccurrences: [null],
    },
    {
      validators: timeRangeValidator('fromTime', 'toTime'),
    }
  );

  constructor() {
    effect(
      () => {
        const isEditEventLoading = this.isEditEventLoading();
        if (!isEditEventLoading.value && !isEditEventLoading.isError) {
          this.eventUpdated.emit(true);
          this.resetCustomOption = true;
          this.closeEditEvent();
        }
      },
      { allowSignalWrites: true }
    );
  }

  onRepeatChange(changePath: boolean, optionName?: DropdownChangeEvent) {
    this.customRepeatVisibility =
      (this.editEventForm.get('repeatOption')?.value?.value === 'custom' && optionName?.value?.value === 'custom') ||
      (!optionName && this.editEventForm.get('repeatOption')?.value?.value === 'custom' && !changePath);
  }

  resetRepeatOption() {
    this.customRepeatVisibility = false;
    this.editEventForm.get('repeatOption')?.setValue(this.repeatOptions[0]);
  }

  saveCustomRepeatOptions(eventCustomOptions: ICustomEventOptions) {
    this.customRepeatVisibility = false;
    this.editEventForm.get('repeatOption')?.setValue(this.repeatOptions[3]);
    this.eventCustomOptions = eventCustomOptions;
  }

  get isShowEditEventValue(): boolean {
    return this.isShowEditEvent();
  }

  set isShowEditEventValue(value: boolean) {
    this.isShowEditEvent.bind(value);
    this.isShowEditEventChange.emit(value);
  }

  closeEditEvent() {
    this.isShowEditEvent.bind(false);
    this.isShowEditEventChange.emit(false);

    this.resetCustomOption = false;
    this.submitted = false;
    this.editEventForm.reset();
    this.confirmationService.close();
  }

  getEventDay() {
    const dayName = this.dayOptionsRepeat[new Date(this.event.startTime).getDay()].dayName;
    this.repeatOptions[2].name = 'Weekly on ' + dayName;
  }

  getCustomRepeatValues() {
    const repeatEvent = this.editEventForm.get('repeatOption')?.value?.id === 0 ? 0 : 1;
    this.editEventForm.get('repeatEvent')?.setValue(repeatEvent);

    if (this.editEventForm.get('repeatOption')?.value?.id === 0) {
      this.editEventForm.get('repeatOption')?.setValue(this.editEventForm.get('repeatOption')?.value);
    } else if (
      this.editEventForm.get('repeatOption')?.value?.id !== 3 &&
      this.editEventForm.get('repeatOption')?.value?.id !== 0
    ) {
      this.editEventForm.get('repeatOption')?.setValue(this.editEventForm.get('repeatOption')?.value);
      const dayName = this.dayOptionsRepeat[new Date(this.event.startTime).getDay()].dayName;
      this.editEventForm.get('repeatDays')?.setValue([dayName.toUpperCase()]);
    } else if (this.editEventForm.get('repeatOption')?.value?.id !== 0 && this.eventCustomOptions) {
      this.editEventForm.get('repeatOption')?.setValue(this.repeatOptions[3]);
      this.editEventForm.get('repeatDays')?.setValue(this.eventCustomOptions.repeatDays);
      this.editEventForm.get('repeatInterval')?.setValue(this.eventCustomOptions.repeatInterval);
      this.editEventForm.get('repeatEndDate')?.setValue(this.eventCustomOptions.repeatEndDate);
      this.editEventForm.get('repeatOccurrences')?.setValue(this.eventCustomOptions.repeatOccurrences);
    }
  }

  editEvent() {
    this.submitted = true;
    this.getCustomRepeatValues();

    let guests: IGuest[] = this.editEventForm.get('guests')?.value?.map(
      (email: any): IGuest => ({
        inviteeEmailId: email,
        availabilityStatus: 0,
        inviteeId: 0,
        isOrganiser: false,
      })
    );
    if (this.profileEmailId()) {
      guests.push({
        inviteeEmailId: this.profileEmailId(),
        availabilityStatus: 0,
        inviteeId: 0,
        isOrganiser: true,
      });
    }

    if (this.editEventForm.valid) {
      const fromTime = this.datePipe.transform(this.editEventForm.get('fromTime')?.value, 'yyyy-MM-dd HH:mm:ss') || '';
      const endTime = this.datePipe.transform(this.editEventForm.get('toTime')?.value, 'yyyy-MM-dd HH:mm:ss') || '';
      const eventDate = this.datePipe.transform(this.editEventForm.get('date')?.value, 'yyyy-MM-ddTHH:mm:ss') || '';
      const event: ICalendarEventPayload = {
        sub: this.editEventForm.get('eventName')?.value ? this.editEventForm.get('eventName')?.value : '',
        eventDate: eventDate,
        startTime: fromTime,
        endTime: endTime,
        allDays: false,
        invitees: guests,
        location: this.editEventForm.get('location')?.value ? this.editEventForm.get('location')?.value : '',
        description: this.editEventForm.get('description')?.value ? this.editEventForm.get('description')?.value : '',
        eventId: this.event.eventId,
        shareStatus: 0,

        repeatEvent: this.editEventForm.get('repeatEvent')?.value,
        repeatFrequency:
          this.editEventForm.get('repeatOption')?.value?.id !== 3
            ? this.editEventForm.get('repeatOption')?.value?.value
            : this.eventCustomOptions?.repeatFrequency,
        repeatDays: this.editEventForm.get('repeatDays')?.value,
        repeatInterval: this.editEventForm.get('repeatInterval')?.value,
        repeatEndDate: this.editEventForm.get('repeatEndDate')?.value,
        repeatOccurrences: this.editEventForm.get('repeatOccurrences')?.value,
      };

      let fd: FormData = new FormData();
      fd.append('data', JSON.stringify(event));
      fd.append('action', '1');

      // this.calendarFacade.CreateEvent(fd);
      this.submitted = false;
    }
  }
}
