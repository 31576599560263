import { Component, DestroyRef, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { EMAIL_ACTIONS, MAILBOX_ROUTES, MENU_MAIL_ITEMS } from '@app/constants';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@app/shared/modules/icons.module';
import { ButtonModule } from 'primeng/button';
import { CustomTreeComponent } from '@app/shared/components/custom-tree/custom-tree.component';
import { SupSidebarComponent } from '@app/shared/components/sup-sidebar/sup-sidebar.component';
import { SidebarMenuItemComponent } from '@app/shared/components/sidebar-menu-item/sidebar-menu-item.component';
import type { MenuItem } from '@app/shared/models/menu-items-sidebar';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { findMatchingItem } from '@app/utils/matching-item';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Folder } from '@app/shared/models/omail';

@Component({
  selector: 'app-mail-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    IconsModule,
    ButtonModule,
    SupSidebarComponent,
    SidebarMenuItemComponent,
    CustomTreeComponent,
  ],
  templateUrl: './mail-sidebar.component.html',
  styleUrl: './mail-sidebar.component.scss',
})
export class MailSidebarComponent implements OnInit, OnDestroy {
  socketService = inject(SocketV2Service);
  menuItems = signal(MENU_MAIL_ITEMS);
  activeItem = '';
  dynamicFolders: Folder[] = [];
  private destroyRef = inject(DestroyRef);

  private routerEventsSubscription!: Subscription;
  private folderDetailsSubscription!: Subscription;
  router = inject(Router);
  route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.folderDetailsSubscription = this.socketService
      .getFolderDetailsStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateMenuBadges();
      });
    this.socketService
      .getDynamicFolderDetailsStream()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((folders) => {
        this.dynamicFolders = folders;
      });
    this.routerEventsSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setActiveIconBasedOnRoute();
      });

    this.setActiveIconBasedOnRoute();
  }

  ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
    if (this.folderDetailsSubscription) {
      this.folderDetailsSubscription.unsubscribe();
    }
  }

  private setActiveIconBasedOnRoute(): void {
    const currentRoute = this.router.url;
    const matchingItem = findMatchingItem(this.menuItems(), currentRoute);
    const isNewEmailRoute = currentRoute.includes(MAILBOX_ROUTES.NEW_EMAIL);
    if (matchingItem) {
      this.activeItem = matchingItem.id;
    } else if (isNewEmailRoute) {
      this.activeItem = MAILBOX_ROUTES.INBOX;
    } else {
      this.activeItem = '';
    }
  }

  onItemClick({ event }: { event: Event; item: MenuItem }) {
    event.stopPropagation();
  }

  onCreateMessage() {
    this.router.navigate([MAILBOX_ROUTES.NEW_EMAIL], {
      relativeTo: this.route,
      queryParams: { action: EMAIL_ACTIONS.NEW_EMAIL },
    });
    this.activeItem = '';
  }
  private updateMenuBadges(): void {
    const folderDetails = this.socketService.getFolderDetails();

    folderDetails.forEach((folder) => {
      const menuItem = this.menuItems().find((item) => {
        if (item.id == MAILBOX_ROUTES.DELETED && folder.folderName.toLocaleLowerCase() == 'trash') {
          return true;
        } else if (item.id == MAILBOX_ROUTES.DRAFT && folder.folderName.toLocaleLowerCase() == 'drafts') {
          return true;
        } else if (item.id == MAILBOX_ROUTES.PRIORITY && folder.folderName.toLocaleLowerCase() == 'important') {
          return true;
        } else if (item.id == folder.folderName.toLocaleLowerCase()) {
          return true;
        }
        return false;
      });
      this.menuItems.set(
        this.menuItems().map((item) => {
          if (item.id === menuItem?.id) {
            return { ...item, badge: folder.totalUnReadMessagesCount };
          }

          return item;
        })
      );
    });
  }
}
