import { Component, DestroyRef, inject, input, model, output, SimpleChanges } from '@angular/core';
import { EmailFieldComponent } from '../email-field/email-field.component';
import { FormGroup } from '@angular/forms';
import { EMAIL_ACTIONS } from '@app/constants';
import { EmailService } from '@app/services/Email.service';
import { IReadEmailResponse } from '@app/shared/models/omail';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContactsFacade } from '@app/pages/my-contacts/services/contacts.facade';

@Component({
  selector: 'app-email-fields',
  standalone: true,
  imports: [EmailFieldComponent],
  templateUrl: './email-fields.component.html',
  styleUrl: './email-fields.component.scss',
})
export class EmailFieldsComponent {
  private emailService = inject(EmailService);
  private destroyRef = inject(DestroyRef);
  private contactsFacade = inject(ContactsFacade);

  emailForm = input<FormGroup>();
  action = input<string>();
  draft = input<IReadEmailResponse>();
  isEditing = model<boolean>(true);

  to: string[] = [];
  cc: string[] = [];
  bcc: string[] = [];
  showCC: boolean = false;
  showBCC: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['draft']) {
      this.handleAction();
    }
  }

  ngOnInit(): void {
    this.contactsFacade
      .getSelectedContact()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((contact) => {
        if (contact?.omailEmailId) {
          this.to = [contact?.omailEmailId];
        } else if (contact?.alternateEmailId) {
          this.to = [contact?.alternateEmailId];
        }
        this.emailForm()?.get('toEmail')?.setValue(this.to.join(','));
      });
    this.handleAction();
  }

  private handleAction(): void {
    const emailDetails = this.emailService.getEmailDetails();
    if (this.action() === EMAIL_ACTIONS.REPLY) {
      this.emailForm()?.get('toEmail')?.setValue(emailDetails?.from);
      this.to = [emailDetails?.from];
    } else if (this.action() === EMAIL_ACTIONS.REPLY_ALL) {
      this.emailForm()
        ?.get('toEmail')
        ?.setValue(this.getEmailsTo([emailDetails?.from, ...emailDetails?.to]).join(','));
      this.emailForm()?.get('ccEmail')?.setValue(emailDetails?.cc?.join(','));
      this.emailForm()?.get('subject')?.setValue(`Re: ${emailDetails?.subject}`);
      this.showCC = (emailDetails?.cc?.length ?? 0) > 0;
      this.cc = emailDetails?.cc || [];
      this.to = this.getEmailsTo([emailDetails?.from, ...emailDetails?.to]);
    } else if (this.action() === EMAIL_ACTIONS.DRAFT) {
      this.to = this.draft()?.to || [];
      this.cc = this.draft()?.cc || [];
      this.bcc = this.draft()?.bcc || [];
      this.showCC = this.cc.length > 0;
      this.showBCC = this.bcc.length > 0;
    }
  }

  // Toggle visibility of CC or BCC fields
  toggleField(type: 'cc' | 'bcc', show: boolean): void {
    if (!show) {
      this.emailForm()
        ?.get(type + 'Email')
        ?.setValue('');
    }
    if (type === 'cc') {
      this.showCC = show;
    } else if (type === 'bcc') {
      this.showBCC = show;
    }
  }

  // Handle email updates
  onEmailsChange(emails: string[], type: 'to' | 'cc' | 'bcc'): void {
    if (type === 'to') {
      this.to = emails;
      this.emailForm()?.get('toEmail')?.setValue(emails.join(','));
    } else if (type === 'cc') {
      this.cc = emails;
      this.emailForm()?.get('ccEmail')?.setValue(emails.join(','));
    } else if (type === 'bcc') {
      this.bcc = emails;
      this.emailForm()?.get('bccEmail')?.setValue(emails.join(','));
    }
  }

  getEmailsTo(emails: string[]) {
    return Array.from(new Set(emails));
  }
}
