<div
  class="email-container p-3 border-round-lg flex items-center gap-2 m-3 cursor-pointer"
  [ngClass]="{ 'email-container--collapsed': !isFullEmailVisible(), 'border-1 shadow-1': isFullEmailVisible() }"
>
  <div
    class="avatar bg-tertiary-50 border border-circle flex justify-content-center align-items-center"
    (click)="toggleEmailDetailsVisibility()"
  >
    <fa-icon icon="user" class="text-xl" />
  </div>

  <div class="flex flex-column gap-1 text-color-tertiary-500 flex-1">
    <div class="flex justify-content-between align-items-center gap-1">
      <div class="flex flex-column gap-1 flex-1" (click)="toggleEmailDetailsVisibility()">
        <div class="flex gap-1 align-items-center">
          <div class="font-semibold">{{ emailDetails()?.fromAliasName }},</div>
          <div class="text-xs">{{ formatDate(emailDetails()?.date || '') }}</div>
        </div>

        <div class="flex gap-1 align-items-center">
          <div class="text-sm">To: {{ emailDetails()?.to?.join(', ') }}</div>
          @if (isFullEmailVisible()) {
            <fa-icon
              icon="caret-down"
              class="cursor-pointer"
              (click)="$event.stopPropagation(); op.toggle($event)"
              pTooltip="Show details"
              tooltipPosition="bottom"
              tooltipStyleClass="text-xs"
            ></fa-icon>
          }
        </div>
        <p-overlayPanel #op [style]="{ width: '450px' }">
          <app-email-detils-overlay-panel [emailDetails]="emailDetails()" />
        </p-overlayPanel>
      </div>
      @if (isFullEmailVisible()) {
        <div class="flex gap-2 align-items-center">
          <app-text-to-speech [textToRead]="formattedEmail" />
          <div (click)="$event.stopPropagation()">
            <app-email-context-menu [emailDetails]="emailDetails()" />
          </div>
        </div>
      }
    </div>

    <div (click)="toggleEmailDetailsVisibility()" [innerHTML]="formatBodyHtml(emailDetails()?.body || '')"></div>

    @if (isFullEmailVisible()) {
      <div class="ellipsis w-full pb-3" [ngClass]="{ 'border-bottom-1 ': expandedEmail }">
        <fa-icon
          icon="ellipsis-h"
          class="ellipsis__icon mt-3 border-round-sm text-color-primary-500 bg-primary-100 flex justify-content-center align-items-center cursor-pointer px-1"
          pTooltip="More actions"
          tooltipStyleClass="text-xs"
          tooltipPosition="bottom"
          (click)="toggleEmail($event)"
        ></fa-icon>
      </div>
      @if (expandedEmail) {
        <app-email-details />
      }
    }

    @if (isFullEmailVisible()) {
      <app-replys class="mt-3" [isTextIncluded]="true" />
    }
  </div>
</div>
