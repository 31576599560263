import { Component, input, model, output } from '@angular/core';
import { EmailChipInputComponent } from '../../../../shared/components/email-chip-input/email-chip-input.component';

@Component({
  selector: 'app-email-field',
  standalone: true,
  imports: [EmailChipInputComponent],
  templateUrl: './email-field.component.html',
  styleUrl: './email-field.component.scss',
})
export class EmailFieldComponent {
  label = input('');
  emails = input<string[]>([]);
  fieldId = input('');
  placeholder = input('');
  removable = input(false);
  isEditing = model(true);
  emailsChange = output<string[]>();
  remove = output<void>();

  onEmailsChange(emails: string[]) {
    this.emailsChange.emit(emails);
  }

  removeField() {
    this.remove.emit();
  }

  toggleEdit(isEditing: boolean): void {
    this.isEditing.set(isEditing);
  }
}
