import { inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  Email,
  folderParams,
  IDeleteEmailPayload,
  IFile,
  IImportantEmailPayload,
  IReadEmailPayload,
  IReadEmailResponse,
  ISaveDraftPayload,
  ISendEmailPayload,
  moveEmailToFolderPayload,
  restoreEmailPayload,
  subfolderDeleteParams,
  subfolderParams,
} from '@app/shared/models/omail';
import { mockEmailResponse } from '@app/mocks/mail';
import { IApiResponseData } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private httpClient = inject(HttpClient);

  private selectedEmail = signal<Email | undefined>(undefined);
  getSelectedEmail = this.selectedEmail.asReadonly();

  oMailApiUrl = environment.oMailApiUrl;
  private emailDetails: IReadEmailResponse = {} as IReadEmailResponse;
  private cachedEmails: Map<string, IReadEmailResponse> = new Map();

  private readonly options = {
    withCredentials: true,
  };

  setSelectedEmail(selectedEmail: Email | undefined): void {
    if (selectedEmail) {
      this.selectedEmail.set({ ...selectedEmail });
    }
  }

  sendEmail(formData: FormData): Observable<IApiResponseData<ISendEmailPayload>> {
    const options = {
      withCredentials: this.options.withCredentials,
    };

    return this.httpClient.post<IApiResponseData<ISendEmailPayload>>(`${this.oMailApiUrl}sendEmail`, formData, options);
  }

  readEmail(payload: IReadEmailPayload): Observable<IReadEmailResponse> {
    if (!payload || !payload.mailId) {
      throw new Error('Invalid email payload');
    }

    return of(payload).pipe(
      switchMap((currentPayload) => {
        const cachedEmail = this.cachedEmails.get(currentPayload.messageId as string);

        if (cachedEmail) {
          return of(cachedEmail);
        }

        return this.httpClient
          .post<IReadEmailResponse>(`${this.oMailApiUrl}api/v2/readMail`, currentPayload, this.options)
          .pipe(
            switchMap((response) => {
              if (response?.sno) {
                this.cachedEmails.set(currentPayload.messageId as string, response);
              }
              return of(response);
            })
          );
      })
    );
  }

  savetoDrafts(payload: ISaveDraftPayload, attachments: IFile[], mailId?: string | number): Observable<any> {
    const apiUrl = mailId ? 'editDraftMails' : 'saveDraft';
    const options = {
      withCredentials: this.options.withCredentials,
    };
    const formData = new FormData();

    formData.append('data', JSON.stringify(payload));
    formData.append('action', 'save');
    if (attachments && attachments.length) {
      attachments.forEach((file) => {
        formData.append('file', file.file);
      });
    }
    if (mailId) {
      formData.append(
        'readMail',
        JSON.stringify({
          mailId,
          folder: 'Drafts',
        })
      );
    }
    return this.httpClient.post<any>(`${this.oMailApiUrl}${apiUrl}`, formData, options);
  }

  markAsReadEmail(payload: IImportantEmailPayload) {
    const options = {
      withCredentials: this.options.withCredentials,
    };

    return this.httpClient.post<any>(`${this.oMailApiUrl}api/v2/markAsRead`, payload, options);
  }

  markAsFavouriteOrImportant(payload: IImportantEmailPayload): Observable<any> {
    const options = {
      withCredentials: this.options.withCredentials,
    };
    return this.httpClient.post<any>(`${this.oMailApiUrl}api/v2/markAsFavouriteOrImportant`, payload, options);
  }

  deleteEmail(payload: IDeleteEmailPayload) {
    return this.httpClient.post<any>(`${this.oMailApiUrl}api/v2/deleteMail`, payload, this.options);
  }

  videoMail(payload: { file: Blob }) {
    const formData = new FormData();

    formData.append('file', payload.file);

    return this.httpClient.post<any>(`${this.oMailApiUrl}uploadVideoMail`, formData, this.options);
  }

  setCurrentEmail(email: IReadEmailResponse): void {
    this.emailDetails = email || ({} as IReadEmailResponse);
  }

  getCurrentEmail(): IReadEmailResponse | undefined {
    return this.emailDetails;
  }

  getEmails(): Email[] {
    return mockEmailResponse.listofMails;
  }

  setEmailDetails(details: IReadEmailResponse): void {
    this.emailDetails = details;
  }

  getEmailDetails(): IReadEmailResponse {
    return this.emailDetails;
  }

  buildSendEmailFormData(payload: ISendEmailPayload, files: IFile[]): FormData {
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));

    if (files && files.length) {
      files.forEach((file) => {
        formData.append('files', file.file); // Adjust the key as needed
      });
    }

    return formData;
  }

  updatedPriorityEmail(messageId: string, important: boolean) {
    const cachedEmail = this.cachedEmails.get(messageId);

    if (cachedEmail) {
      cachedEmail.important = important;

      this.cachedEmails.set(messageId, cachedEmail);
    }
  }

  createFolder(payload: folderParams) {
    if (payload.folderId) {
      return this.httpClient.post<any>(`${environment.oMailApiUrl}api/v2/updateFolder`, payload, this.options);
    } else {
      return this.httpClient.post<any>(`${environment.oMailApiUrl}api/v2/createFolder`, payload, this.options);
    }
  }
  moveEmail(payload: moveEmailToFolderPayload) {
    return this.httpClient.post<any>(environment.oMailApiUrl + 'api/v2/moveMail', payload, this.options);
  }

  deleteFolder(payload: subfolderDeleteParams) {
    return this.httpClient.post<any>(`${environment.oMailApiUrl}api/v2/deleteFolder`, payload, this.options);
  }

  restoreEmail(payload: restoreEmailPayload) {
    return this.httpClient.post<any>(`${environment.oMailApiUrl}api/v2/restoreDeletedMails`, payload, this.options);
  }
}
