import { Component, inject } from '@angular/core';
import { MailSidebarComponent } from '@app/components/mail/mail-sidebar/mail-sidebar.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { EmailService } from '@app/services/Email.service';

@Component({
  selector: 'app-mail',
  standalone: true,
  imports: [MailSidebarComponent, RouterModule],
  templateUrl: './mail.component.html',
  styleUrl: './mail.component.scss',
})
export class MailComponent {
  route = inject(ActivatedRoute);
  router = inject(Router);
  emailService = inject(EmailService);
}
