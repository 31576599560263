import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { OButtonDirective } from 'o-suite-lib';
import { ICalendarDropdownOptions, ICustomEventOptions, IDayRepeatOption } from '@app/shared/models';
import { dayOptionsRepeat, endsAfterOptions, everyOptions } from '../calendar.constants';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { format } from 'date-fns';

@Component({
  selector: 'app-custom-repeat',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    InputNumberModule,
    DropdownModule,
    RadioButtonModule,
    CalendarModule,
    OButtonDirective,
    FormsModule,
    ReactiveFormsModule,
    FaIconComponent,
  ],
  templateUrl: './custom-repeat.component.html',
  styleUrl: './custom-repeat.component.scss',
})
export class CustomRepeatComponent {
  @Input('resetCustomOption') set setResetCustomOption(value: boolean) {
    if (value) {
      this.resetCustomFields();
    }
  }

  @Input('eventCustomOptions') set setEventCustomOptions(value: ICustomEventOptions | null) {
    this.eventCustomOptions = value;

    if (!value) return;

    dayOptionsRepeat.forEach((dayOption) => {
      dayOption.status = value.repeatDays.includes(dayOption.dayKey);
    });

    if (value.repeatEndDate) {
      this.endsValue = 'on';
      this.endsOnDate = new Date(value.repeatEndDate);
    }

    this.repeatPeriod = everyOptions.find((option) => option.value === value.repeatFrequency) || this.repeatPeriod;
    this.repeatEvery = Number(value.repeatInterval) > 0 ? Number(value.repeatInterval) : this.repeatEvery;

    const occurrences = this.endsAfterOptions.find((option) => option.value === value.repeatOccurrences?.toString());
    if (occurrences) {
      this.endsValue = 'after';
      this.endsAfterOccurrences = occurrences;
    }
  }

  @Input('selectedDayDate') set setSelectedDayInfo(selectedDayDate: Date) {
    if (selectedDayDate) {
      this.endsOnDate = selectedDayDate;

      if (!this.eventCustomOptions) {
        const eventDay: number = selectedDayDate.getDay();
        this.dayOptionsRepeat.map((option: IDayRepeatOption) => {
          option.status = option.id === eventDay;
        });
      }
    }
  }

  @Input() customRepeatVisibility: boolean = false;
  @Output() customRepeatVisibilityChange: EventEmitter<boolean> = new EventEmitter();
  @Output() resetRepeatOption: EventEmitter<boolean> = new EventEmitter();
  @Output() saveCustomRepeatOptions: EventEmitter<ICustomEventOptions> = new EventEmitter();

  everyOptions: ICalendarDropdownOptions[] = everyOptions;
  endsAfterOptions: ICalendarDropdownOptions[] = endsAfterOptions;
  dayOptionsRepeat: IDayRepeatOption[] = [...dayOptionsRepeat];
  endsValue: string = 'never';
  repeatEvery: number = 1;
  repeatPeriod: ICalendarDropdownOptions = { name: 'Week', value: 'WEEKLY' };
  endsOnDate: Date = new Date();
  endsAfterOccurrences: ICalendarDropdownOptions = { name: '10 occurrences', value: '10' };
  eventCustomOptions: ICustomEventOptions | null = null;

  onCloseCustomRepeatModal() {
    this.resetCustomFields();
    this.customRepeatVisibilityChange.emit();
    this.resetRepeatOption.emit();
  }

  changeDayStatus(id: number) {
    if (this.dayOptionsRepeat[id].status) {
      const selectedDay = this.dayOptionsRepeat.filter((day) => day.status === true).length;
      if (selectedDay > 1) this.dayOptionsRepeat[id].status = false;
    } else {
      this.dayOptionsRepeat[id].status = !this.dayOptionsRepeat[id].status;
    }
  }

  onSubmitSave() {
    const dayOptionsRepeat = this.dayOptionsRepeat
      .filter((day: IDayRepeatOption) => day.status === true)
      .map((day: IDayRepeatOption) => day.dayKey);

    const customEventOptions: ICustomEventOptions = {
      repeatInterval: this.repeatEvery,
      repeatDays: dayOptionsRepeat,
      repeatFrequency: this.repeatPeriod?.value,
      repeatEndDate: this.endsValue === 'on' ? format(new Date(this.endsOnDate), 'yyyy-MM-dd') : null,
      repeatOccurrences: this.endsValue === 'after' ? this.endsAfterOccurrences.value : null,
    };

    this.saveCustomRepeatOptions.emit(customEventOptions);
  }

  resetCustomFields() {
    this.endsValue = 'never';
    this.repeatEvery = 1;
    this.repeatPeriod = { name: 'Week', value: 'WEEKLY' };
    this.endsOnDate = new Date();
    this.endsAfterOccurrences = { name: '10 occurrences', value: '10' };
    this.dayOptionsRepeat = [...dayOptionsRepeat];
  }
}
