import { Component, DestroyRef, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { EMAIL_ACTIONS, MAILBOX_ROUTES } from '@app/constants';
import { EmailService } from '@app/services/Email.service';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { IDeleteEmailPayload, IReadEmailResponse, moveEmailToFolderPayload } from '@app/shared/models/omail';
import { IconsModule } from '@app/shared/modules/icons.module';
import { environment } from '@src/environments/environment';
import { OToastV2Service } from 'o-suite-lib';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { CreateFolderComponent } from '../../create-folder/create-folder.component';

interface MenuItem {
  label?: string;
  styleClass?: string;
  items?: MenuItem[];
  command?: () => void;
  separator?: boolean;
  icon?: string;
  iconClass?: string;
  iconStyle?: { [key: string]: string };
}

@Component({
  selector: 'app-email-context-menu',
  standalone: true,
  imports: [TieredMenuModule, IconsModule, ProgressSpinnerModule, CreateFolderComponent],
  templateUrl: './email-context-menu.component.html',
  styleUrl: './email-context-menu.component.scss',
})
export class EmailContextMenuComponent {
  socketService = inject(SocketV2Service);
  emailService = inject(EmailService);
  router = inject(Router);
  toaster = inject(OToastV2Service);
  destroyRef = inject(DestroyRef);
  route = inject(ActivatedRoute);

  emailDetails = input<IReadEmailResponse>();

  menuItems: MenuItem[] = [];
  showReplyAll = false;
  isDeleteOrMoveInProgress = signal(false);
  displayCreateFolderDialog = signal(false);

  ngOnInit(): void {
    this.initializeMenuItems();
  }

  private initializeMenuItems(): void {
    this.socketService.getDynamicFolderDetailsStream().subscribe((folders) => {
      const folderItems = this.getFilteredFolderItems(folders);
      this.menuItems = this.buildMenuItems(folderItems);
    });
  }

  private getFilteredFolderItems(folders: any[]): MenuItem[] {
    if (!folders || folders.length === 0) {
      return [];
    }

    let filteredFolders = folders.filter(
      (folder) => folder.folderName.toLowerCase() !== this.emailDetails()?.folderName?.toLowerCase()
    );

    if (this.emailDetails()?.folderName?.toLowerCase() !== 'inbox') {
      filteredFolders = [
        {
          folderName: 'INBOX',
          folderColorCode: 'primary',
          folderId: 0,
        },
        ...filteredFolders,
      ];
    }

    return filteredFolders.map((folder) => ({
      label: folder.folderName,
      icon: 'pi pi-circle-on',
      iconClass: 'menu-item-icon text-sm',
      command: () => this.moveToFolder(folder.folderName),
      iconStyle: { color: `var(--${folder.folderColorCode})`, fontSize: '12px' },
    }));
  }

  private buildMenuItems(folderItems: MenuItem[]): MenuItem[] {
    const currentUrl = this.router.url;

    const createNewFolderItem: MenuItem = {
      label: 'Create New Folder',
      command: () => this.displayCreateFolderDialog.set(true),
    };

    const deleteEmailItem: MenuItem = {
      label: 'Delete Email',
      styleClass: 'menu-item-delete text-sm',
      command: () => this.moveToTrash(),
    };

    const replyOptions: MenuItem[] = [
      {
        label: 'Reply',
        styleClass: 'text-sm',
        command: () => this.navigateToCreateEmail(EMAIL_ACTIONS.REPLY),
      },
      this.checkReplyAllVisibility()
        ? {
            label: 'Reply All',
            styleClass: 'text-sm',
            command: () => this.navigateToCreateEmail(EMAIL_ACTIONS.REPLY_ALL),
          }
        : null,
      {
        label: 'Forward',
        styleClass: 'text-sm',
        command: () => this.navigateToCreateEmail(EMAIL_ACTIONS.FORWARD),
      },
    ].filter((item) => item !== null);

    if (
      [MAILBOX_ROUTES.Folder, MAILBOX_ROUTES.INBOX, MAILBOX_ROUTES.NEW_EMAIL].some((route) =>
        currentUrl.includes(route)
      )
    ) {
      return [
        ...replyOptions,
        {
          label: 'Move to',
          styleClass: 'text-sm',
          items: [...folderItems, folderItems.length > 0 ? { separator: true } : null, createNewFolderItem].filter(
            (item) => item !== null
          ),
        },
        deleteEmailItem,
      ];
    }
    return [...replyOptions, deleteEmailItem];
  }

  moveToFolder(destinationFolder: string): void {
    const payload: moveEmailToFolderPayload = {
      action: 'move',
      mailIds: [this.emailDetails()?.sno!],
      destinationFolder,
      folder: this.emailDetails()?.folderName!,
      role: false,
    };

    this.isDeleteOrMoveInProgress.set(true);

    this.emailService
      .moveEmail(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: any) => {
          this.socketService.deleteEmails({
            mailIds: [this.emailDetails()?.sno!],
            folder: this.emailDetails()?.folderName || '',
          });

          this.toaster.add({
            severity: 'success',
            summary: res?.message || `Emails moved successfully`,
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.isDeleteOrMoveInProgress.set(false);
          this.router.navigate(['../'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        },
        error: (error) => {
          this.toaster.add({
            severity: 'error',
            summary: error?.error?.message || `Error while moving emails`,
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
          this.isDeleteOrMoveInProgress.set(false);
        },
      });
  }

  moveToTrash() {
    const payload: IDeleteEmailPayload = {
      mailIds: [this.emailDetails()?.sno!],
      folder: this.emailDetails()?.folderName || '',
    };
    this.isDeleteOrMoveInProgress.set(true);
    this.emailService
      .deleteEmail(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          console.log('res', res);
          this.toaster.add({
            severity: 'success',
            summary: res.message,
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.socketService.deleteEmails({
            mailIds: [this.emailDetails()?.sno!],
            folder: this.emailDetails()?.folderName || '',
          });
          this.isDeleteOrMoveInProgress.set(false);
          this.router.navigate(['../'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        },
        error: (err) => {
          this.toaster.add({
            severity: 'error',
            summary: err?.error?.message,
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
          this.isDeleteOrMoveInProgress.set(false);
        },
      });
  }

  private checkReplyAllVisibility(): boolean {
    const toRecipients = this.emailDetails()?.to ?? [];
    const ccRecipients = this.emailDetails()?.cc ?? [];
    const bccRecipients = this.emailDetails()?.bcc ?? [];

    const totalVisibleRecipients = toRecipients.length + ccRecipients.length;

    if (totalVisibleRecipients <= 1) {
      return false;
    }

    if (toRecipients.length === 0 && ccRecipients.length === 0 && bccRecipients.length > 0) {
      return false;
    }

    return true;
  }

  navigateToCreateEmail(action: string): void {
    const emailDetails = this.emailDetails();

    if (emailDetails) {
      this.emailService.setCurrentEmail(emailDetails);
      this.router.navigate([`${MAILBOX_ROUTES.MAIL}/${MAILBOX_ROUTES.NEW_EMAIL}/${emailDetails?.sno}`], {
        queryParams: { action },
      });
    }
  }
}
