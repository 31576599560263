import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { IconsModule } from '@app/shared/modules/icons.module';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-upload-profile-pic',
  standalone: true,
  imports: [AvatarModule, IconsModule],
  templateUrl: './upload-profile-pic.component.html',
  styleUrl: './upload-profile-pic.component.scss',
})
export class UploadProfilePicComponent {
  @Input('userImage') userImage: string | null = null;
  @Output() userImageChange = new EventEmitter();
  @Output() userImageUrlChange = new EventEmitter();

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files) {
      const files = Array.from(input.files);
      if (!files[0].type.startsWith('image/')) {
        console.error('Selected file is not an image');
      } else {
        const file: File = files[0];

        const reader = new FileReader();
        reader.onload = () => {
          this.userImage = reader.result as string;
          this.userImageUrlChange.emit(reader.result as string);
        };

        reader.readAsDataURL(file);
        this.userImageChange.emit(file);
      }
    }
  }

  triggerFileUpload(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  handleClearImage() {
    this.userImage = null;
    this.userImageChange.emit(null);
  }

  protected readonly environment = environment;
}
