<p-dialog [modal]="true" [(visible)]="isShowEditEventValue" styleClass="edit-event-modal" (onHide)="closeEditEvent()">
  <ng-template pTemplate="content">
    <div class="flex flex-column w-full">
      <div class="flex flex-col items-center w-full gap-4 px-3 mb-2 mt-3 pb-0">
        <form [formGroup]="editEventForm" (ngSubmit)="editEvent()" class="p-fluid w-full">
          <div class="field">
            <input
              pInputText
              class="create-event-input"
              id="eventTitle"
              type="text"
              formControlName="eventName"
              placeholder="Add event name"
              [ngClass]="{
                'ng-invalid ng-dirty create-event-input-error':
                  (editEventForm.get('eventName')?.touched || submitted) && editEventForm.get('eventName')?.invalid,
              }"
            />
            @if ((editEventForm.get('eventName')?.touched || submitted) && editEventForm.get('eventName')?.invalid) {
              <small class="text-red-500">Event name is required!</small>
            }
          </div>

          <div class="field w-full flex flex-row align-items-center justify-content-between">
            <div class="flex flex-row align-items-center w-7">
              <fa-icon icon="clock" class="color-denim-gray-40"></fa-icon>
              <p-calendar
                [styleClass]="'create-event-date-input'"
                [dateFormat]="displayFormat"
                formControlName="date"
                class="ml-2 event-calendar w-full"
                appendTo="body"
              />
            </div>
            <p-calendar
              [styleClass]="'create-event-date-input create-event-time-input'"
              inputId="calendar-timeonly"
              class="event-calendar"
              formControlName="fromTime"
              [timeOnly]="true"
              [hourFormat]="'12'"
              [ngClass]="{
                'ng-invalid ng-dirty create-event-input-error':
                  (editEventForm.get('toTime')?.touched || editEventForm.get('fromTime')?.touched || submitted) &&
                  (editEventForm.getError('invalidTimeRange') || editEventForm.getError('sameTimeRange')),
              }"
            />
            -
            <p-calendar
              [styleClass]="'create-event-date-input create-event-time-input'"
              inputId="calendar-timeonly"
              class="event-calendar time-event-calendar"
              formControlName="toTime"
              [timeOnly]="true"
              [hourFormat]="'12'"
              [ngClass]="{
                'ng-invalid ng-dirty create-event-input-error':
                  (editEventForm.get('toTime')?.touched || editEventForm.get('fromTime')?.touched || submitted) &&
                  (editEventForm.getError('invalidTimeRange') || editEventForm.getError('sameTimeRange')),
              }"
            />
          </div>
          @if (
            (editEventForm.get('toTime')?.touched || editEventForm.get('fromTime')?.touched || submitted) &&
            editEventForm.getError('invalidTimeRange')
          ) {
            <small class="text-red-500">Meeting start time cannot be after end time.</small>
          }
          @if (
            (editEventForm.get('toTime')?.touched || editEventForm.get('fromTime')?.touched || submitted) &&
            editEventForm.getError('sameTimeRange')
          ) {
            <small class="text-red-500">Meeting start time and end time cannot match.</small>
          }

          <!--          <div class="field mt-4">-->
          <!--            <p-checkbox-->
          <!--              id="mail_calendar_checkbox_allDay"-->
          <!--              inputId="mail_calendar_checkbox_allDay"-->
          <!--              binary="true"-->
          <!--            ></p-checkbox>-->
          <!--            <label for="mail_calendar_checkbox_allDay" class="text-sm ml-2 mb-0">All day</label>-->
          <!--          </div>-->

          <div class="field mt-4">
            <p-dropdown
              [options]="repeatOptions"
              (onChange)="onRepeatChange(true, $event)"
              formControlName="repeatOption"
              optionLabel="name"
              class="repeat-dropdown"
              styleClass="w-12rem"
              placeholder="Select a repeat option"
            >
              <ng-template let-option pTemplate="item">
                <div class="flex align-items-center gap-2" (click)="onRepeatChange(false)">
                  <div>{{ option.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>

          <p-divider />

          <div class="field mt-5 mb-0 flex flex-row align-items-baseline">
            <fa-icon icon="user-group" class="color-denim-gray-40 mr-3 w-1rem"></fa-icon>
            <p-floatLabel class="w-full">
              <p-chips
                [max]="100"
                [allowDuplicate]="false"
                [addOnBlur]="true"
                id="chips"
                styleClass="guests-float-group"
                formControlName="guests"
                [ngClass]="{
                  'ng-invalid ng-dirty create-event-input-error':
                    (editEventForm.get('guests')?.touched || submitted) && editEventForm.get('guests')?.invalid,
                }"
              >
                <ng-template let-item pTemplate="item">
                  {{ item }}
                </ng-template>
              </p-chips>
              <label for="chips">Add guests</label>
            </p-floatLabel>
          </div>
          @if (
            (editEventForm.get('guests')?.touched || submitted) &&
            !editEventForm.get('guests')?.getError('invalidEmails') &&
            editEventForm.get('guests')?.invalid
          ) {
            <small class="text-red-500 ml-5">Guests is required!</small>
          }
          @if (
            (editEventForm.get('guests')?.touched || submitted) &&
            editEventForm.get('guests')?.getError('invalidEmails')
          ) {
            <small class="text-red-500 ml-5">Invalid guest email address</small>
          }

          <div class="field mt-4 flex flex-row align-items-baseline">
            <fa-icon icon="location-dot" size="lg" class="color-denim-gray-40 mr-3 w-1rem"></fa-icon>
            <input type="text" pInputText class="w-full" placeholder="Add location" formControlName="location" />
          </div>

          <div class="field mt-4 flex flex-row align-items-baseline">
            <fa-icon icon="notes-medical" size="lg" class="color-denim-gray-40 mr-3 w-1rem"></fa-icon>
            <p-editor
              #pEditor
              id="editDoc"
              class="event-custom-editor w-full"
              styleClass="event-custom-editor__contaciner"
              formControlName="description"
            >
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <button class="ql-bold" aria-label="Bold"></button>
                  <button class="ql-italic" aria-label="Italic"></button>
                  <button class="ql-underline" aria-label="Underline"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
                  <button class="ql-list" value="bullet" aria-label="Bullet List"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-align" value="" aria-label="Align Left"></button>
                  <button class="ql-align" value="center" aria-label="Align Center"></button>
                  <button class="ql-align" value="right" aria-label="Align Right"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-image" aria-label="Insert Image"></button>
                </span>
              </ng-template>
            </p-editor>
          </div>

          <div class="field mt-8 w-full flex flex-row justify-content-end">
            <p-button
              label="Save"
              type="submit"
              styleClass="create-event-btn"
              [loading]="isEditEventLoading().value"
            ></p-button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</p-dialog>

<app-custom-repeat
  [(customRepeatVisibility)]="customRepeatVisibility"
  [eventCustomOptions]="eventCustomOptions"
  [selectedDayDate]="this.editEventForm.get('date')?.value"
  [resetCustomOption]="resetCustomOption"
  (resetRepeatOption)="resetRepeatOption()"
  (saveCustomRepeatOptions)="saveCustomRepeatOptions($event)"
></app-custom-repeat>
