import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { CreateEmailHeaderComponent } from './create-email-header/create-email-header.component';
import { EmailEditorComponent } from './email-editor/email-editor.component';
import { CreateEmailFooterComponent } from './create-email-footer/create-email-footer.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EMAIL_ACTIONS, MAILBOX_ROUTES } from '@app/constants';
import {
  Email,
  IFile,
  IImportantEmailPayload,
  IReadEmailPayload,
  IReadEmailResponse,
  ISaveDraftPayload,
} from '@app/shared/models/omail';
import { EmailService } from '@app/services/Email.service';
import { environment } from '@src/environments/environment';
import { CommonModule } from '@angular/common';
import { OLoaderComponent, OToastV2Service } from 'o-suite-lib';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, map, Observable, of, finalize, switchMap } from 'rxjs';
import { CanDeactivateService } from '@app/services/CanDeactivateService.service';
import { EmailFieldsComponent } from '../shared/email-fields/email-fields.component';
import { EmailOverviewComponent } from '../email-actions/email-overview.component';

@Component({
  selector: 'app-create-email',
  standalone: true,
  imports: [
    CreateEmailHeaderComponent,
    EmailEditorComponent,
    CreateEmailFooterComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    OLoaderComponent,
    EmailFieldsComponent,
    EmailOverviewComponent,
  ],
  templateUrl: './create-email.component.html',
  styleUrl: './create-email.component.scss',
})
export class CreateEmailComponent implements OnInit {
  route = inject(ActivatedRoute);
  emailService = inject(EmailService);
  socketService = inject(SocketV2Service);
  draftData: IReadEmailResponse | undefined;
  destroyRef = inject(DestroyRef);
  router = inject(Router);
  toasterService = inject(OToastV2Service);
  canDeactivateService = inject(CanDeactivateService);
  action: string = '';
  emailDetails: IReadEmailResponse | undefined;
  uploadedFile: IFile | null = null;
  isDraftedOrSubmitted = signal(false);
  isEmailReplied = signal(true);
  draftLoading = signal(false);
  loading = signal(false);
  readLoading = signal(false);
  closePageLoading = signal(false);
  isEditing = signal(false);
  attachments: IFile[] = [];
  videoUrl: Blob | null = null;

  emailForm: FormGroup = new FormGroup({
    toEmail: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    ccEmail: new FormControl<string>('', {
      nonNullable: true,
    }),
    bccEmail: new FormControl<string>('', {
      nonNullable: true,
    }),
    subject: new FormControl<string>('', {
      validators: [Validators.required, Validators.minLength(3)],
      nonNullable: true,
    }),
    priority: new FormControl<string | number>(0, {
      nonNullable: true,
    }),
    email: new FormControl<string>('', {
      validators: [Validators.required, Validators.minLength(5)],
      nonNullable: true,
    }),
  });

  canDeactivate(): boolean | Observable<boolean> {
    if (this.isDraftedOrSubmitted() || !this.formIsDirty()) {
      return true;
    }
    return this.onSaveDraftClick(true, true).pipe(
      map(() => true),
      catchError(() => of(true))
    );
  }

  ngOnInit(): void {
    const currentUrl = this.router.url;

    this.canDeactivateService.setCanDeactivate(this.canDeactivate.bind(this));

    this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      if (params.get('emailId')) this.updateEmailForm(params.get('emailId') || '');
    });
    this.route.queryParamMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      this.resetForm();
      this.action = params.get('action') || EMAIL_ACTIONS.NEW_EMAIL;
      this.isEmailReplied.set(
        [EMAIL_ACTIONS.REPLY, EMAIL_ACTIONS.REPLY_ALL, EMAIL_ACTIONS.FORWARD].includes(this.action)
      );
      if (this.action === EMAIL_ACTIONS.FORWARD) {
        this.isEditing.set(true);
      }
      if (currentUrl.includes(MAILBOX_ROUTES.DRAFT)) {
        this.action = EMAIL_ACTIONS.DRAFT;
      }
      this.emailDetails = this.emailService.getEmailDetails() as IReadEmailResponse;
    });
  }

  updateEmailForm(emailId: String): void {
    const draftEmail = this.socketService.getFolderData('drafts')?.emails.find((email) => email.sno == +emailId)!;
    const payload: IReadEmailPayload = {
      mailId: +emailId,
      folder: draftEmail.folderName || '',
      important: draftEmail.priority != '0' ? true : false,
      messageId: draftEmail.messageId,
      reply: draftEmail.reply,
      threadType: null,
    };
    this.readLoading.set(true);
    this.emailService
      .readEmail(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: IReadEmailResponse) => {
          const payload: IImportantEmailPayload = {
            msgs: [emailId + ''],
            folder: draftEmail.folderName || '',
            read: true,
          };
          this.emailService.markAsReadEmail(payload).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({});

          this.emailForm.patchValue({
            toEmail: response.to?.join(', ') ?? '',
            ccEmail: response.cc?.join(', ') ?? '',
            bccEmail: response.bcc?.join(', ') ?? '',
            subject: response.subject ?? '',
            priority: response.priority ?? 0,
            email: response.body ?? '',
          });
          this.draftData = response;
          this.readLoading.set(false);
        },
        error: (error) => {
          console.error('Error:', error);
          this.readLoading.set(false);
        },
      });

    if (draftEmail) {
    }
  }

  handleVideoUrl(videoUrl: Blob): void {
    this.videoUrl = videoUrl;
  }

  handleFileUpload(fileData: IFile): void {
    this.uploadedFile = fileData;
    this.attachments.push({
      file: fileData.file,
      fileUrl: fileData.fileUrl,
      fileName: fileData.fileName,
      fileType: fileData.fileType,
      fileSize: fileData.fileSize,
    });
  }

  resetForm() {
    this.emailForm.reset({
      toEmail: '',
      ccEmail: '',
      bccEmail: '',
      subject: '',
      priority: 0,
      email: '',
    });
    this.attachments = [];
    this.videoUrl = null;
    this.uploadedFile = null;
    this.isDraftedOrSubmitted.set(false);
    this.isEmailReplied.set(false);
    this.draftLoading.set(false);
    this.loading.set(false);
    this.readLoading.set(false);
    this.closePageLoading.set(false);
    this.isEditing.set(false);
  }

  onSaveDraftClick(isClosingEmail: boolean, cancelNavigate?: boolean): Observable<boolean> {
    if (!this.formIsDirty()) {
      if (isClosingEmail) {
        this.router.navigate([MAILBOX_ROUTES.MAIL, MAILBOX_ROUTES.INBOX]);
      }
      return of(true);
    }

    const payload: ISaveDraftPayload = {
      toEmail: this.emailForm.get('toEmail')?.value,
      ccEmail: this.emailForm.get('ccEmail')?.value,
      bccEmail: this.emailForm.get('bccEmail')?.value,
      subject: this.emailForm.get('subject')?.value,
      messageHeader: this.emailForm.get('email')?.value,
      scheduledTimes: '',
      email: this.emailForm.get('email')?.value || '',
    };

    if (this.videoUrl) {
      return this.uploadVideoAndSaveDraft(payload, isClosingEmail, cancelNavigate);
    }

    return this.saveDraft(payload, isClosingEmail, cancelNavigate);
  }

  private uploadVideoAndSaveDraft(
    payload: ISaveDraftPayload,
    isClosingEmail: boolean,
    cancelNavigate?: boolean
  ): Observable<boolean> {
    if (isClosingEmail) {
      this.closePageLoading.set(true);
    } else {
      this.draftLoading.set(true);
    }

    return this.emailService.videoMail({ file: this.videoUrl! }).pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap((res) => {
        const videoUrl = res?.object;
        if (videoUrl) {
          const videoHtml = `
            <div class="video-block">
              <video id="video-mail-element" class="fr-active" contenteditable="false" controls src="${videoUrl}"></video>
            </div>`;
          payload.email += videoHtml;
        }
        return this.saveDraft(payload, isClosingEmail, cancelNavigate);
      }),
      catchError((error) => {
        this.toasterService.add({
          severity: 'error',
          summary: error?.message,
          icon: environment.publicPath + '/assets/icons/toast/error.svg',
        });
        return of(false);
      }),
      finalize(() => {
        this.draftLoading.set(false);
        this.closePageLoading.set(false);
      })
    );
  }

  private saveDraft(payload: ISaveDraftPayload, isClosedEmail: boolean, cancelNavigate?: boolean): Observable<boolean> {
    if (isClosedEmail) {
      this.closePageLoading.set(true);
    } else {
      this.draftLoading.set(true);
    }

    const emailId = this.action === EMAIL_ACTIONS.DRAFT ? this.draftData?.sno : '';

    return this.emailService.savetoDrafts(payload, this.attachments, emailId).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((res) => {
        this.toasterService.add({
          severity: 'success',
          summary: res.message,
          icon: environment.publicPath + '/assets/icons/toast/success.svg',
        });

        if (!cancelNavigate) {
          this.isDraftedOrSubmitted.set(true);
          this.router.navigate([MAILBOX_ROUTES.MAIL, MAILBOX_ROUTES.INBOX]);
        }
        return true;
      }),
      catchError((error) => {
        this.toasterService.add({
          severity: 'error',
          summary: error?.error?.message,
          icon: environment.publicPath + '/assets/icons/toast/error.svg',
        });
        return of(false);
      }),
      finalize(() => {
        this.draftLoading.set(false);
        this.closePageLoading.set(false);
      })
    );
  }

  encodeHtml(html: string): string {
    const element = document.createElement('div');
    element.innerText = html;
    return element.innerHTML;
  }

  onSubmitEmail() {
    if (this.emailForm.valid) {
      const formValue = this.emailForm.value;
      formValue.isVideoMail = !!this.videoUrl;
      let emailHtml = '';

      // Build the payload

      this.loading.set(true);

      if (this.videoUrl) {
        this.emailService
          .videoMail({ file: this.videoUrl })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (res) => {
              const videoUrl = res?.object;

              const videoHtml = `<div class="video-block">
          <video id="video-mail-element" controls src="${videoUrl}"></video>
      </div>
              `;

              formValue.email = formValue.email + videoHtml + emailHtml;

              const formData = this.emailService.buildSendEmailFormData(formValue, this.attachments); // Add uploadedFiles

              // Proceed to send the email
              this.emailService
                .sendEmail(formData)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                  next: (res) => {
                    this.isDraftedOrSubmitted.set(true);
                    this.router.navigate([MAILBOX_ROUTES.MAIL, MAILBOX_ROUTES.SENT]);
                    this.toasterService.add({
                      severity: 'success',
                      summary: res?.message,
                      icon: environment.publicPath + '/assets/icons/toast/success.svg',
                    });
                    this.loading.set(false);
                  },
                  error: (error) => {
                    const errorMessage = error?.error.message || 'An error occurred while sending the email';
                    this.toasterService.add({
                      severity: 'error',
                      summary: errorMessage,
                      icon: environment.publicPath + '/assets/icons/toast/error.svg',
                    });
                    this.loading.set(false);
                  },
                });
            },
            error: (error) => {
              const errorMessage = error?.error.message || 'An error occurred while sending the email';
              this.toasterService.add({
                severity: 'error',
                summary: errorMessage,
                icon: environment.publicPath + '/assets/icons/toast/error.svg',
              });
              this.loading.set(false);
            },
          });
      } else {
        formValue.email = `
        <div style="display: flex; flex-direction: column; gap: 15px; justify-content: space-between;">
        <div>
          ${formValue.email || ''}
          </div>
          ${emailHtml}
        </div>
      `;
        const formData = this.emailService.buildSendEmailFormData(formValue, this.attachments);

        this.emailService
          .sendEmail(formData)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (res) => {
              this.isDraftedOrSubmitted.set(true);
              this.router.navigate([MAILBOX_ROUTES.MAIL, MAILBOX_ROUTES.SENT]);
              this.toasterService.add({
                severity: 'success',
                summary: res?.message,
                icon: environment.publicPath + '/assets/icons/toast/success.svg',
              });
              this.loading.set(false);
            },
            error: (error) => {
              const errorMessage = error?.error.message || 'An error occurred while sending the email';

              this.toasterService.add({
                severity: 'error',
                summary: errorMessage,
                icon: environment.publicPath + '/assets/icons/toast/error.svg',
              });
              this.loading.set(false);
            },
          });
      }
    } else {
      const missingFields = [];
      if (this.emailForm.get('toEmail')?.invalid) {
        missingFields.push('Recipient (To Email)');
      }
      if (this.emailForm.get('subject')?.invalid) {
        missingFields.push('Subject');
      }
      if (this.emailForm.get('email')?.invalid) {
        missingFields.push('Content');
      }

      const missingFieldsMessage = missingFields.join(', ');

      this.emailForm.markAllAsTouched();

      this.toasterService.add({
        severity: 'info',
        summary: `Missing fields: ${missingFieldsMessage}`,
        icon: environment.publicPath + '/assets/icons/toast/info.svg',
      });

      return;
    }
  }

  formIsDirty(): boolean {
    const draftValues = {
      toEmail: this.draftData?.to?.join(', ') || '',
      ccEmail: this.draftData?.cc?.join(', ') || '',
      bccEmail: this.draftData?.bcc?.join(', ') || '',
      subject: this.draftData?.subject || '',
      email: this.draftData?.body || '',
    };

    const currentValues = this.emailForm.value;

    if (this.draftData?.sno) {
      return !!(
        draftValues.toEmail !== currentValues.toEmail ||
        draftValues.ccEmail !== currentValues.ccEmail ||
        draftValues.bccEmail !== currentValues.bccEmail ||
        draftValues.subject !== currentValues.subject ||
        draftValues.email !== currentValues.email ||
        this.videoUrl ||
        this.attachments.length > 0
      );
    }
    return !!(
      currentValues.toEmail ||
      currentValues.ccEmail ||
      currentValues.bccEmail ||
      currentValues.subject ||
      currentValues.email ||
      this.videoUrl ||
      this.attachments.length > 0
    );
  }
}
