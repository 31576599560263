<p-dialog [modal]="true" [(visible)]="customRepeatVisibility" [draggable]="false" [closable]="false">
  <ng-template pTemplate="content">
    <fa-icon icon="close" class="text-lg cursor-pointer close-icon" (click)="onCloseCustomRepeatModal()"></fa-icon>
    <div class="flex flex-column align-items-center">
      <p class="text-3xl text-color-tertiary-500 font-bold">Custom recurrence</p>
      <div class="flex flex-row align-items-center w-full">
        <p class="text-base text-color-tertiary-500 font-medium m-0 mr-3">Repeat every</p>
        <p-inputNumber
          mode="decimal"
          styleClass="every-input-number"
          inputId="withoutgrouping"
          [min]="1"
          [useGrouping]="false"
          [(ngModel)]="repeatEvery"
        />
        <p-dropdown
          [options]="everyOptions"
          optionLabel="name"
          styleClass="every-options-dropdown"
          [(ngModel)]="repeatPeriod"
        />
      </div>

      @if (repeatPeriod.value === 'WEEKLY') {
        <div class="w-full mt-5">
          <p class="text-base text-color-tertiary-500 m-0 mb-3 font-medium">Conditions</p>
          <div class="days flex flex-row align-items-center">
            @for (day of dayOptionsRepeat; track day.id) {
              <div
                class="border-circle ml-2 w-2rem h-2rem bg-primary-100 flex justify-content-center align-items-center cursor-pointer"
                [class.bg-primary-500]="day.status"
                (click)="changeDayStatus(day.id)"
              >
                <p class="text-base text-color-primary-500 font-medium" [class.white-color]="day.status">
                  {{ day.key }}
                </p>
              </div>
            }
          </div>
        </div>
      }

      <div class="ends-options w-full mt-5">
        <p class="text-base text-color-tertiary-500 m-0 mb-3 font-medium">Ends</p>

        <div class="flex flex-row align-items-end">
          <div class="flex flex-column justify-content-center mt-1">
            <div class="flex align-items-center">
              <p-radioButton
                inputId="mail_customRepeat_radio_never"
                name="ends-radio"
                value="never"
                [(ngModel)]="endsValue"
              />
              <label for="mail_customRepeat_radio_never" class="ml-2">Never</label>
            </div>
            <div class="flex align-items-center mt-5">
              <p-radioButton
                inputId="mail_customRepeat_radio_on"
                name="ends-radio"
                value="on"
                [(ngModel)]="endsValue"
              />
              <label for="mail_customRepeat_radio_on" class="ml-2">On</label>
            </div>
            <div class="flex align-items-center mt-5">
              <p-radioButton
                inputId="mail_customRepeat_radio_after"
                name="ends-radio"
                value="after"
                [(ngModel)]="endsValue"
              />
              <label for="mail_customRepeat_radio_after" class="ml-2">After</label>
            </div>
          </div>

          <div class="flex flex-column mt-3">
            <p-calendar
              styleClass="ends-option-input"
              [disabled]="endsValue !== 'on'"
              [minDate]="endsOnDate"
              [(ngModel)]="endsOnDate"
              appendTo="body"
            />

            <p-dropdown
              [disabled]="endsValue !== 'after'"
              [options]="endsAfterOptions"
              optionLabel="name"
              [(ngModel)]="endsAfterOccurrences"
              styleClass="ends-option-input ends-option-dropdown"
            />
          </div>
        </div>
      </div>

      <div class="action-buttons mt-8 flex flex-row align-items-center w-full justify-content-end">
        <p-button
          [outlined]="true"
          label="Cancel"
          styleClass="custom-repeat-action-btn"
          (onClick)="onCloseCustomRepeatModal()"
        ></p-button>
        <p-button
          o-button
          [oButtonSeverity]="'success'"
          label="Save"
          styleClass="custom-repeat-action-btn"
          class="ml-3"
          (onClick)="onSubmitSave()"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
