import { Component, DestroyRef, inject, signal } from '@angular/core';
import { EmailListComponent } from '../../../components/mail/email-list/email-list.component';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Email } from '@app/shared/models/omail';
import { filter, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-priority',
  standalone: true,
  imports: [EmailListComponent, RouterOutlet],
  templateUrl: './priority.component.html',
  styleUrl: './priority.component.scss',
})
export class PriorityComponent {
  socketService = inject(SocketV2Service);
  boxName = 'Important';
  listofMails = signal<Email[]>([]);
  loading = signal(true);
  router = inject(Router);
  route = inject(ActivatedRoute);
  showEmailDetail = false;
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.fetchFolderData();
    this.socketService
      .getMailboxDataStream(this.boxName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((emailResponse) => {
        this.listofMails.set(emailResponse.emails);
        this.loading.set(false);
      });
    this.route.firstChild?.paramMap
      .pipe(map((paramMap) => paramMap.get('emailId')))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((emailId) => {
        this.showEmailDetail = !!emailId;
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.route.firstChild?.snapshot.paramMap.get('emailId')) this.showEmailDetail = true;
        else this.showEmailDetail = false;
      });
  }

  private fetchFolderData(): void {
    const cachedData = this.socketService.getFolderData(this.boxName);

    if (cachedData) {
      this.listofMails.set(cachedData.emails);
      this.loading.set(false);
    } else {
      this.loading.set(true);
      this.socketService.requestMailboxData(this.boxName);
    }
  }
}
