import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { EmailHeaderComponent } from './email-header/email-header.component';
import { EmailDetailComponent } from './email-detail/email-detail.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Email, IDeleteEmailPayload,
  IImportantEmailPayload,
  IReadEmailPayload,
  IReadEmailResponse,
  restoreEmailPayload,
} from '@app/shared/models/omail';
import { Button } from 'primeng/button';
import { EmailService } from '@app/services/Email.service';
import { IconsModule } from '@app/shared/modules/icons.module';
import { OButtonDirective, OLoaderComponent, OToastV2Service } from 'o-suite-lib';
import { MAILBOX_ROUTES } from '@app/constants';
import { SocketV2Service } from '@app/services/socket-v2.service';
import { environment } from '@src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { formatDate } from '@app/utils/format-date';

interface MailboxAction {
  iconName: string;
  text: string;
  textButton: string;
  clickButton: (emailDetails: IReadEmailResponse) => void;
  textButton2?: string;
  clickButton2?: () => void;
}
@Component({
  selector: 'app-email-body-content',
  standalone: true,
  imports: [
    EmailHeaderComponent,
    EmailDetailComponent,
    ScrollPanelModule,
    IconsModule,
    Button,
    OButtonDirective,
    OLoaderComponent,
  ],
  templateUrl: './email-body-content.component.html',
  styleUrl: './email-body-content.component.scss',
})
export class EmailBodyContentComponent implements OnInit {
  route = inject(ActivatedRoute);
  emailService = inject(EmailService);
  socketService = inject(SocketV2Service);
  router = inject(Router);
  toaster = inject(OToastV2Service);
  sanitizer = inject(DomSanitizer);
  private destroyRef = inject(DestroyRef);

  loading = signal(false);
  showEmailDetail = signal(false);
  isDeleteOrMoveInProgress = signal(false);
  isDeletingEmailLoading = signal(false);
  isEmailRestoreOrCancelLoading = signal(false);
  emailDetails: IReadEmailResponse | undefined;
  currentMailbox = '';

  readonly mailboxActions: MailboxAction[] = [
    {
      iconName: 'trash-can',
      text: 'Deleted messages older than 30 days are auto-deleted',
      textButton: 'Restore',
      clickButton: (emailDetails: IReadEmailResponse) => this.restoreEmail(emailDetails),
      textButton2: 'Delete Forever',
      clickButton2: () => {},
    },
    {
      iconName: 'envelope-circle-check',
      text: 'Send scheduled for Tomorrow, 7:51 PM',
      textButton: 'Cancel Send',
      clickButton: (emailDetails: IReadEmailResponse) => this.deleteScheduledEmail(emailDetails),
    },
  ];

  selectedAction?: MailboxAction;

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      if (params.get('emailId')) this.handleRouteChange(params.get('emailId') || '');
      else this.router.navigate(['../'], { relativeTo: this.route });
    });
  }

  restoreEmail(emailDetails: IReadEmailResponse): void {
    const payload: restoreEmailPayload = {
      messageIds: [emailDetails?.messageId!],
      sourceFolder: emailDetails?.folderName!,
    };

    this.isEmailRestoreOrCancelLoading.set(true);

    this.emailService
      .restoreEmail(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.isEmailRestoreOrCancelLoading.set(false);
          this.toaster.add({
            severity: 'success',
            summary: res?.message || 'Email restored',
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.socketService.deleteEmails({
            mailIds: [emailDetails?.sno!],
            folder: emailDetails?.folderName || '',
          });
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: (err) => {
          this.isEmailRestoreOrCancelLoading.set(false);
          this.toaster.add({
            severity: 'error',
            summary: err?.error?.message || 'Something went wrong!',
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
        },
      });
  }

  handleRouteChange(emailId: string): void {
    const segments = this.router.url.split('/');
    const mailIndex = segments.indexOf(MAILBOX_ROUTES.MAIL);
    let folderEmails: Email[] | undefined;
    if (mailIndex !== -1 && mailIndex + 1 < segments.length) {
      this.currentMailbox = segments[mailIndex + 1];
      if (this.currentMailbox.includes(MAILBOX_ROUTES.Folder)) {
        this.route.queryParams.subscribe((params) => {
          if (params['fn']) {
            this.currentMailbox = params['fn'];
            folderEmails = this.socketService.getFolderData(this.currentMailbox)?.emails;
          }
        });
      } else if (this.currentMailbox == MAILBOX_ROUTES.DELETED) {
        folderEmails = this.socketService.getFolderData('trash')?.emails;
      } else if (this.currentMailbox == MAILBOX_ROUTES.DRAFT) {
        folderEmails = this.socketService.getFolderData('drafts')?.emails;
      } else if (this.currentMailbox == MAILBOX_ROUTES.PRIORITY) {
        folderEmails = this.socketService.getFolderData('important')?.emails;
      } else {
        folderEmails = this.socketService.getFolderData(this.currentMailbox)?.emails;
      }
      const checkMailId = folderEmails?.find((mail) => mail.sno == +emailId);

      if (!checkMailId) {
        this.router.navigate(['../'], { relativeTo: this.route });
      } else {
        this.loading.set(true);
        const payload: IReadEmailPayload = {
          mailId: emailId,
          folder: checkMailId.folderName || '',
          important: checkMailId.priority != '0' ? true : false,
          messageId: checkMailId.messageId,
          reply: checkMailId.reply,
          threadType: null,
        };
        this.emailService
          .readEmail(payload)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (response: IReadEmailResponse) => {
              const payload: IImportantEmailPayload = {
                msgs: [checkMailId?.sno!],
                folder: checkMailId?.folderName || '',
                read: true,
              };
              this.emailService.markAsReadEmail(payload).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({});
              this.emailDetails = response;
              if (this.currentMailbox === MAILBOX_ROUTES.DELETED) {
                this.selectedAction = this.mailboxActions[0];
              } else if (this.currentMailbox === MAILBOX_ROUTES.SCHEDULED) {
                this.selectedAction = this.mailboxActions[1];
                this.selectedAction.text=`Send scheduled at ${formatDate(this.emailDetails?.date!)}`;
              } else {
                this.selectedAction = undefined;
              }
              this.loading.set(false);
            },
            error: (error) => {
              console.error('Error:', error);
              this.loading.set(false);
            },
            complete: () => {
              // Handle completion here, if needed
            },
          });
      }


    }
  }

  formatBodyHtml(body: string) {
    return this.sanitizer.bypassSecurityTrustHtml(body.replace(/\\/g, ''));
  }

  addImageExtension(attachments: any) {
    if (attachments != undefined) {
      if (attachments.length > 0) {
        let result = attachments.map((element: any, index: number, arr: any) => {
          const fileEx = element.fileName.split('.').pop().toLowerCase();
          let attachmentsObj = Object.assign({}, element);
          attachmentsObj['fileExtensions'] = '';
          attachmentsObj['attachmentName'] = attachmentsObj.fileName?.split('.')[0].slice(0, 20) + '...';
          attachmentsObj['size'] = attachmentsObj.size?.split(' ')[0];
          attachmentsObj['storageUnit'] = element.size?.split(' ')[1];
          switch (fileEx) {
            case 'jpg':
              attachmentsObj['fileExtensions'] = 'jpg';
              break;
            case 'jpeg':
              attachmentsObj['fileExtensions'] = 'jpeg';
              break;
            case 'png':
              attachmentsObj['fileExtensions'] = 'png';
              break;
            case 'svg':
              attachmentsObj['fileExtensions'] = 'svg';
              break;
            case 'zip':
              attachmentsObj['fileExtensions'] = 'zip';
              break;
            case 'csv':
              attachmentsObj['fileExtensions'] = 'csv';
              break;
            case 'docx':
              attachmentsObj['fileExtensions'] = 'docx';
              break;
            case 'xlsx':
              attachmentsObj['fileExtensions'] = 'xlsx';
              break;
            case 'pptx':
              attachmentsObj['fileExtensions'] = 'pptx';
              break;
            case 'pdf':
              attachmentsObj['fileExtensions'] = 'pdf';
              break;
            case 'mp3':
              attachmentsObj['fileExtensions'] = 'mp3';
              break;
            case 'mp4':
              attachmentsObj['fileExtensions'] = 'mp4';
              break;
            default:
              attachmentsObj['fileExtensions'] = 'default';
              break;
          }
          return attachmentsObj;
        });
        return result;
      }
    }
  }

  private deleteScheduledEmail(emailDetails: IReadEmailResponse) {
    console.log('emailDetails', emailDetails)
    const payload: IDeleteEmailPayload = {
      mailIds: [emailDetails?.sno],
      folder: emailDetails?.folderName!,
    };

    this.isEmailRestoreOrCancelLoading.set(true);

    this.emailService
      .deleteEmail(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.isEmailRestoreOrCancelLoading.set(false);
          this.toaster.add({
            severity: 'success',
            summary: res?.message || 'Email deleted',
            icon: environment.publicPath + '/assets/icons/toast/success.svg',
          });
          this.socketService.deleteEmails({
            mailIds: [emailDetails?.sno!],
            folder: emailDetails?.folderName || '',
          });
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: (err) => {
          this.isEmailRestoreOrCancelLoading.set(false);
          this.toaster.add({
            severity: 'error',
            summary: err?.error?.message || 'Something went wrong!',
            icon: environment.publicPath + '/assets/icons/toast/error.svg',
          });
        },
      });
  }
}
