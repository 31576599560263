import { ICalendarDropdownOptions, IDayRepeatOption } from '@app/shared/models';

export const everyOptions: ICalendarDropdownOptions[] = [
  {
    name: 'Week',
    value: 'WEEKLY',
  },
  {
    name: 'Month',
    value: 'MONTHLY',
  },
  {
    name: 'Year',
    value: 'YEARLY',
  },
];

export const endsAfterOptions: ICalendarDropdownOptions[] = [
  {
    name: '5 occurrences',
    value: '5',
  },
  {
    name: '10 occurrences',
    value: '10',
  },
  {
    name: '15 occurrences',
    value: '15',
  },
  {
    name: '20 occurrences',
    value: '20',
  },
];

export const repeatOptions: ICalendarDropdownOptions[] = [
  {
    id: 0,
    name: 'Do not repeat',
    value: 'dontRepeat',
  },
  {
    id: 1,
    name: 'Daily',
    value: 'DAILY',
  },
  {
    id: 2,
    name: 'Weekly on',
    value: 'WEEKLY',
  },
  {
    id: 3,
    name: 'Custom',
    value: 'custom',
  },
];

export const dayOptionsRepeat: IDayRepeatOption[] = [
  {
    id: 0,
    key: 'S',
    dayName: 'Sunday',
    dayKey: 'SUNDAY',
  },
  {
    id: 1,
    key: 'M',
    dayName: 'Monday',
    dayKey: 'MONDAY',
  },
  {
    id: 2,
    key: 'T',
    dayName: 'Tuesday',
    dayKey: 'TUESDAY',
  },
  {
    id: 3,
    key: 'W',
    dayName: 'Wednesday',
    dayKey: 'WEDNESDAY',
  },
  {
    id: 4,
    key: 'T',
    dayName: 'Thursday',
    dayKey: 'THURSDAY',
  },
  {
    id: 5,
    key: 'F',
    dayName: 'Friday',
    dayKey: 'FRIDAY',
  },
  {
    id: 6,
    key: 'S',
    dayName: 'Saturday',
    dayKey: 'SATURDAY',
  },
];
