import { Component, effect, EventEmitter, inject, input, Input, Output, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CalendarModule } from 'primeng/calendar';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { IconsModule } from '@app/shared/modules/icons.module';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputNumberModule } from 'primeng/inputnumber';
import {
  ICalendarDropdownOptions,
  ICalendarEventPayload,
  ICustomEventOptions,
  IDayRepeatOption,
  IGuest,
} from '@app/shared/models';
import { dayOptionsRepeat, repeatOptions } from '@app/pages/calendar/calendar.constants';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DateSelectArg } from '@fullcalendar/core';
import { ChipsModule } from 'primeng/chips';
import { FloatLabelModule } from 'primeng/floatlabel';
import { StyleClassModule } from 'primeng/styleclass';
import { Editor, EditorModule } from 'primeng/editor';
import { CalendarFacade } from '@app/pages/calendar/services/calendar.facade';
import { emailArrayValidator, timeRangeValidator } from '@app/pages/calendar/services/calendar-validators';
import { CustomRepeatComponent } from '@app/pages/calendar/custom-repeat/custom-repeat.component';

@Component({
  selector: 'app-create-event-popup',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    ConfirmDialogModule,
    FaIconComponent,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    DividerModule,
    IconsModule,
    ConfirmPopupModule,
    InputNumberModule,
    RadioButtonModule,
    ChipsModule,
    FloatLabelModule,
    StyleClassModule,
    EditorModule,
    CustomRepeatComponent,
  ],
  providers: [ConfirmationService, DatePipe],
  templateUrl: './create-event-popup.component.html',
  styleUrl: './create-event-popup.component.scss',
})
export class CreateEventPopupComponent {
  private fb = inject(FormBuilder);
  private confirmationService = inject(ConfirmationService);
  private calendarFacade = inject(CalendarFacade);
  private datePipe = inject(DatePipe);

  @Input('selectedDayInfo') set setSelectedDayInfo(value: { selectedDayInfo: DateSelectArg; isOpenPopup: boolean }) {
    if (value) {
      if (value.isOpenPopup) this.closeCreatEvent();
      this.selectInfo = value.selectedDayInfo;
      this.eventForm.get('date')?.setValue(this.selectInfo.start);
      this.eventForm.get('fromTime')?.setValue(this.selectInfo.start);
      this.eventForm.get('toTime')?.setValue(this.selectInfo.end);

      this.eventForm.get('repeatOption')?.setValue(this.repeatOptions[0]);

      this.getEventDay();
      this.openCreateEventPopup();
    }
  }

  @Output('eventCreated') eventCreated: EventEmitter<boolean> = new EventEmitter();

  profileEmailId = input.required<string>();
  isCreateEventLoading = this.calendarFacade.isCreateEventLoading;

  customRepeatVisibility: boolean = false;
  repeatOptions: ICalendarDropdownOptions[] = repeatOptions;
  selectInfo!: DateSelectArg;
  displayFormat: string = 'DD, MM d, yy';
  submitted: boolean = false;
  resetCustomOption: boolean = false;
  dayOptionsRepeat: IDayRepeatOption[] = dayOptionsRepeat;
  eventCustomOptions: ICustomEventOptions | null = null;
  @ViewChild('pEditor') pEditor!: Editor;

  eventForm: FormGroup = this.fb.group(
    {
      eventName: ['', Validators.required],
      date: [undefined, Validators.required],
      fromTime: [undefined, Validators.required],
      toTime: [undefined, Validators.required],
      guests: [[], [Validators.required, emailArrayValidator()]],
      location: [''],
      description: [''],
      repeatOption: [undefined],
      repeatEvent: [0],
      repeatDays: [[]],
      repeatInterval: [null],
      repeatEndDate: [null],
      repeatOccurrences: [null],
    },
    {
      validators: timeRangeValidator('fromTime', 'toTime'),
    }
  );

  constructor() {
    effect(
      () => {
        const isCreateEventLoading = this.isCreateEventLoading();
        if (!isCreateEventLoading.value && !isCreateEventLoading.isError) {
          this.resetCustomOption = true;
          this.eventCreated.emit();
          this.closeCreatEvent();
        }
      },
      { allowSignalWrites: true }
    );
  }

  onEventDateChange(date: Date): void {
    const selectedDate = new Date(date);
    const fromTime = this.eventForm.get('fromTime')?.value;
    const toTime = this.eventForm.get('toTime')?.value;

    if (fromTime && toTime) {
      this.eventForm.patchValue({
        fromTime: this.mergeDateWithTime(selectedDate, fromTime),
        toTime: this.mergeDateWithTime(selectedDate, toTime),
      });
    }
  }

  private mergeDateWithTime(date: Date, time: Date): Date {
    return new Date(date.setHours(time.getHours(), time.getMinutes(), time.getSeconds()));
  }

  onRepeatChange(changePath: boolean, optionName?: DropdownChangeEvent) {
    this.customRepeatVisibility =
      (this.eventForm.get('repeatOption')?.value?.value === 'custom' && optionName?.value?.value === 'custom') ||
      (!optionName && this.eventForm.get('repeatOption')?.value?.value === 'custom' && !changePath);
  }

  openCreateEventPopup() {
    this.confirmationService.confirm({
      target: this.selectInfo?.jsEvent?.target as EventTarget,
      dismissableMask: false,
      key: 'custom',
    });
  }

  resetRepeatOption() {
    this.customRepeatVisibility = false;
    this.eventForm.get('repeatOption')?.setValue(this.repeatOptions[0]);
  }

  saveCustomRepeatOptions(eventCustomOptions: ICustomEventOptions) {
    this.customRepeatVisibility = false;
    this.eventForm.get('repeatOption')?.setValue(this.repeatOptions[3]);
    this.eventCustomOptions = eventCustomOptions;
  }

  closeCreatEvent() {
    this.resetCustomOption = false;
    this.submitted = false;
    this.eventForm.reset();
    this.confirmationService.close();
  }

  getEventDay() {
    const dayName = this.dayOptionsRepeat[this.selectInfo.start.getDay()].dayName;
    this.repeatOptions[2].name = 'Weekly on ' + dayName;
  }

  getCustomRepeatValues() {
    const repeatEvent = this.eventForm.get('repeatOption')?.value?.id === 0 ? 0 : 1;
    this.eventForm.get('repeatEvent')?.setValue(repeatEvent);

    if (this.eventForm.get('repeatOption')?.value?.id === 0) {
      this.eventForm.get('repeatOption')?.setValue(this.eventForm.get('repeatOption')?.value);
    } else if (
      this.eventForm.get('repeatOption')?.value?.id !== 3 &&
      this.eventForm.get('repeatOption')?.value?.id !== 0
    ) {
      this.eventForm.get('repeatOption')?.setValue(this.eventForm.get('repeatOption')?.value);
      const dayName = this.dayOptionsRepeat[this.selectInfo.start.getDay()].dayName;
      this.eventForm.get('repeatDays')?.setValue([dayName.toUpperCase()]);
    } else if (this.eventForm.get('repeatOption')?.value?.id !== 0 && this.eventCustomOptions) {
      this.eventForm.get('repeatOption')?.setValue(this.repeatOptions[3]);
      this.eventForm.get('repeatDays')?.setValue(this.eventCustomOptions.repeatDays);
      this.eventForm.get('repeatInterval')?.setValue(this.eventCustomOptions.repeatInterval);
      this.eventForm.get('repeatEndDate')?.setValue(this.eventCustomOptions.repeatEndDate);
      this.eventForm.get('repeatOccurrences')?.setValue(this.eventCustomOptions.repeatOccurrences);
    }
  }

  createEvent() {
    this.submitted = true;
    this.getCustomRepeatValues();

    if (this.eventForm.valid) {
      let guests: IGuest[] = this.eventForm.get('guests')?.value?.map(
        (email: any): IGuest => ({
          inviteeEmailId: email,
          availabilityStatus: 0,
          inviteeId: 0,
          isOrganiser: false,
        })
      );
      if (this.profileEmailId()) {
        guests.push({
          inviteeEmailId: this.profileEmailId(),
          availabilityStatus: 0,
          inviteeId: 0,
          isOrganiser: true,
        });
      }
      const fromTime = this.datePipe.transform(this.eventForm.get('fromTime')?.value, 'yyyy-MM-dd HH:mm:ss') || '';
      const endTime = this.datePipe.transform(this.eventForm.get('toTime')?.value, 'yyyy-MM-dd HH:mm:ss') || '';
      const eventDate = this.datePipe.transform(this.eventForm.get('date')?.value, 'yyyy-MM-ddTHH:mm:ss') || '';

      const event: ICalendarEventPayload = {
        sub: this.eventForm.get('eventName')?.value ? this.eventForm.get('eventName')?.value : '',
        eventDate: eventDate,
        startTime: fromTime,
        endTime: endTime,
        allDays: false,
        invitees: guests,
        location: this.eventForm.get('location')?.value ? this.eventForm.get('location')?.value : '',
        description: this.eventForm.get('description')?.value ? this.eventForm.get('description')?.value : '',
        eventId: 0,
        shareStatus: 0,

        repeatEvent: this.eventForm.get('repeatEvent')?.value,
        repeatFrequency:
          this.eventForm.get('repeatOption')?.value?.id !== 3
            ? this.eventForm.get('repeatOption')?.value?.value
            : this.eventCustomOptions?.repeatFrequency,
        repeatDays: this.eventForm.get('repeatDays')?.value,
        repeatInterval: this.eventForm.get('repeatInterval')?.value,
        repeatEndDate: this.eventForm.get('repeatEndDate')?.value,
        repeatOccurrences: this.eventForm.get('repeatOccurrences')?.value,
      };

      let fd: FormData = new FormData();
      fd.append('data', JSON.stringify(event));
      fd.append('action', '1');

      this.calendarFacade.CreateEvent(fd);
      this.submitted = false;
    }
  }
}
