import { assetUrl } from '@src/single-spa/asset-url';
import { Component, inject, input, signal, SimpleChanges } from '@angular/core';
import { formatDate } from '@app/utils/format-date';
import { TextToSpeechComponent } from '../shared/text-to-speech/text-to-speech.component';
import { IconsModule } from '@app/shared/modules/icons.module';
import { TooltipModule } from 'primeng/tooltip';
import { ReplysComponent } from '../shared/replys/replys.component';
import { CommonModule } from '@angular/common';
import { EmailDetailsComponent } from './email-details/email-details.component';
import { IReadEmailResponse } from '@app/shared/models/omail';
import { EmailDetilsOverlayPanelComponent } from '../shared/email-detils-overlay-panel/email-detils-overlay-panel.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailContextMenuComponent } from '../shared/email-context-menu/email-context-menu.component';
import { EmailFormatterService } from '@app/services/email-format.service';

@Component({
  selector: 'app-email-overview',
  standalone: true,
  imports: [
    TextToSpeechComponent,
    IconsModule,
    OverlayPanelModule,
    TooltipModule,
    ReplysComponent,
    CommonModule,
    EmailDetailsComponent,
    EmailDetilsOverlayPanelComponent,
    EmailContextMenuComponent,
  ],
  templateUrl: './email-overview.component.html',
  styleUrl: './email-overview.component.scss',
})
export class EmailOverviewComponent {
  sanitizer = inject(DomSanitizer);
  emailFormatterService = inject(EmailFormatterService);

  showFullEmail = input<boolean>(false);
  canBeClicked = input<boolean>(true);
  emailDetails = input<IReadEmailResponse>();

  assetUrl = assetUrl;
  expandedEmail = false;
  isFullEmailVisible = signal(false);

  formattedEmail: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['emailDetails']) {
      this.formattedEmail = this.emailFormatterService.formatEmailForReading(this.emailDetails());
    }
  }

  ngOnInit(): void {
    this.isFullEmailVisible.set(this.showFullEmail());
  }

  toggleEmailDetailsVisibility(): void {
    if (this.canBeClicked()) {
      this.isFullEmailVisible.set(!this.isFullEmailVisible());
    }
  }

  toggleEmail(event: Event): void {
    event.stopPropagation();

    this.expandedEmail = !this.expandedEmail;
  }

  showOverlay(event: MouseEvent, overlayPanel: any) {
    overlayPanel.toggle(event);
  }
  formatDate(date: string | number): string {
    return formatDate(date, true);
  }
  formatBodyHtml(body: string) {
    return this.sanitizer.bypassSecurityTrustHtml(body.replace(/\\/g, ''));
  }
}
